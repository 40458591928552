import { useEffect, useState } from "react";
import { useFavoriteEservice, useGetCategoriesList, useGetEserviceList, useRequestJoinEservice } from "../../api";
import StarSvg from "../../../../shared/components/icons/StarSvg";
import PeopleSvg from "../../../../shared/components/icons/PeopleSvg";
import FilterSvg from "../../../../shared/components/icons/FilterSvg";
import { Modal, MultipleAdvancedDropDownList, TextArea } from "kls-ui";
import ListSvg from "../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../shared/components/icons/GridSvg";
import { FilterBarContentProps } from "../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../components/ui/filter-bar/FilterBarInput";
import { CardGrid } from "../../components/ui/card-grid/CardGrid";
import { FilterBar } from "../../components/ui/filter-bar/FilterBar";
import { BreadCrumb, EmptyData } from "../../../../shared/components";
import { convertToEserviceCard } from "../../api/eservice/formatteur";
import { Table } from "../../components/ui/table/Table";
import { ROLE_LIST_ESERVICE } from "../../utils/constants/components.constants";
import { PageContentContainer } from "../../layouts";
import { message } from "antd";
import { useHandleDomLocation } from "../../hooks";
import { MaterialIcon } from "../../../../shared/components/icons";
import styles from '../organisation-pages/eservices-pages/eservices-pages.module.css'
import { Eservice } from "../../components/ui/cards/base-cards/CardEservice";
import { useNavigate } from "react-router-dom";
import { FilterBarSortByProps } from "../../components/ui/filter-bar/FilterBarSortBy";
import { FavoriteEserviceRequest, ListEserviceRequest } from "../../types";

export const CataloguePage = () => {
  const { profilePathName } = useHandleDomLocation()
  const [searchValue, setSearchValue] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const [contribute, setContribute] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{
    [key: string]: string | undefined;
  }>({ value: 'recent', label: 'Récent' });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedCardOption, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);
  const [errorMessageJoin, setErrorMessageJoin] = useState('');
  const [note, setNote] = useState('');
  const [industry, setIndustry] = useState<string>('');
  const [status, setStatus] = useState<string>(profilePathName =='guichet' ? '' : 'Public');
  const [visibility, setVisibility] = useState<string>(profilePathName =='guichet' ? '' : 'Publish');
  const [roleFiltre, setRoleFiltre] = useState<string>('');
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
  const [totalNumber, setTotalNumber] = useState(0);
  const [reset, setReset] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [allEservicesParams, setAllEservicesParams] =
    useState<ListEserviceRequest>({
      numPage: 1,
      pageSize: 10,
      contributeur: false,
      status: status,
      reference: '',
      favorite: false,
      industry: '',
      entity: '',
      guichetName: '',
      idGuichet: '',
      typePublication: visibility,
      role: '',
      Fields: 'ALL',
    });

  const getEserviceList = useGetEserviceList(allEservicesParams);
  const [favoriteRequest, setFavoriteRequest] = useState<FavoriteEserviceRequest>({
    idEservice: '',
    favorite: false
  });


  const renderFiltres = () => {
    if (profilePathName === 'guichet') {
      return [
        {
          data: [
            {
              label: 'Brouillon',
              value: 'Brouillon'
            },
            {
              label: 'Public',
              value: 'Public'
            },
            {
              label: 'Non Publiée',
              value: 'NonPublic'
            },


          ],
          label: 'Statut',
          placeHolder: 'Statut',
          selectedValue: status,
          onChange: (e: string) => {
            console.log("wa e",e)
            {
              if (page > 1) {
                setPage(1);
              }
              if (e == status) return;
              setCardPropsData([]);
              setStatus(e);
            }
          }

        },
        {
          data: [
            { label: 'Publish', value: '' },
            { label: 'Private Service', value: '' },

          ],
          label: 'Visibilité',
          placeHolder: 'Visibilité',
          selectedValue: visibility,
          onChange: (e: string) => {
            {
              if (page > 1) {
                setPage(1);
              }
              if (e == visibility) return;
              setCardPropsData([]);
              setVisibility(e);
            }
          }
        }
        , {
          data: [
            { label: 'Administrateur', value: 'Administrateur' },
            { label: 'Citizen developer', value: 'Citizen developer' },
            { label: 'Contibuteur', value: 'Contibuteur' }

          ],
          label: 'Rôle',
          placeHolder: 'Role',
          selectedValue: roleFiltre,
          onChange: (e: string) => {
            {
              if (page > 1) {
                setPage(1);
              }
              if (e == roleFiltre) return;
              setCardPropsData([]);
              setRoleFiltre(e);
            }
          }
        }

      ]
    }
    else return []

  }

  const renderContribute = () => {
    if (profilePathName === 'guichet') {
      return [
        {
          icon: PeopleSvg,
          label: 'Où je contribue',
          isSelected: contribute,
          handleClick: (contribute: boolean) => {
            if (page > 1) {
              setPage(1);
            }
            setCardPropsData([]);
            setContribute(!contribute);
          }
        }]
    }
    else return []
  }
  useFavoriteEservice(favoriteRequest);
  const navigate = useNavigate();
  const getCategoriesList = useGetCategoriesList();
  const [cardPropsData, setCardPropsData] = useState<Eservice[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [values, setValues] = useState<string[]>([]);
  const filteredRoleList = ROLE_LIST_ESERVICE.filter(role => {
    if (values.includes('Administrateur') && role.value === 'Citizen developer') {
      return false;
    }
    if (values.includes('Citizen developer') && role.value === 'Administrateur') {
      return false;
    }
    return true;
  });

  const postRequestJoinEservice = useRequestJoinEservice();
  const [messageApi, contextHolder] = message.useMessage();
  const fBsEserv: FilterBarSortByProps = {
    items: [
      {
        data: getCategoriesList.customHandler.data,
        label: 'Catégorie(s)',
        placeHolder: 'Catégorie(s)',
        selectedValue: industry,
        multiple: true,
        clearable: true,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == industry) return;
            setCardPropsData([]);
            setIndustry(e);
          }
        }

      },
      ...renderFiltres()
    ]
  };
  const fBc: FilterBarContentProps = {
    label: 'Catalogue',
    searchLength: totalNumber,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => {
          if (page > 1) {
            setPage(1);
          }
          setCardPropsData([]);
          setFavorite(!favorite);
        }
      },
      ...renderContribute()
    ],

  };
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters,
      reset: reset,
      resetClick: () => {
        setRoleFiltre('');
        setVisibility(profilePathName =='guichet' ? '' : 'Publish');
        setStatus(profilePathName =='guichet' ? '' : 'Public');
        setIndustry('');
        setReset(false);
      }
    },

    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        }
        setCardPropsData([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue('');
    },
    onSearch: (e) => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue(e);
    },
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
  };
  const onChange = (options: [{ label: string, value: string }]) => {
    console.log(options);
    const valuesArray = options.map(option => option.value);
    setValues(valuesArray);
  }

  const handleJoinEservice = () => {
    if (!values || values.length === 0) {
      setErrorMessageJoin("Ce champ est requis");
      return;
    }
    if (values.length >= 1) {
      if (selectedCardOption?.id) {
        postRequestJoinEservice.mutate({
          note: note,
          roles: values,
          EserviceId: selectedCardOption.id
        });
      } else {
        console.error('Id is not available');
      }
      setSelectedCardOption(null);
      setNote('');
      setValues([]);
    }
  }

  useEffect(() => {
    if (roleFiltre || (visibility && profilePathName =='guichet') || (status && profilePathName =='guichet') || industry) {
      setReset(true)
    } else {
      setReset(false)
    }
    setIsFetching(true);
    setAllEservicesParams((prevData) => ({
      ...prevData,
      recent: sortBy.value,
      name: searchValue,
      favorite: favorite,
      numPage: page,
      contributeur: contribute,
      status: status,
      typePublication: visibility,
      industry: industry,
      role: roleFiltre
    }));



  }, [searchValue, page, contribute, favorite, sortBy, status, industry, visibility, roleFiltre]);

  useEffect(() => {
    if (postRequestJoinEservice.isPending) {
      messageApi.loading({
        content: 'Loading...',
        duration: 0
      });

    }
    if (postRequestJoinEservice.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'Votre demande a été soumise avec succès',
        duration: 2.5
      });
    }
    if (postRequestJoinEservice.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postRequestJoinEservice.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postRequestJoinEservice.status]);

  useEffect(() => {
    if (getEserviceList.isFetching) {
      setIsFetching(true);
    }
    if (getEserviceList.isSuccess) {
      const eserviceData = getEserviceList.data.body.data;
      const totalItems = getEserviceList.data.body.pagination.totalLength;

      if (eserviceData) {
        if (totalItems !== undefined) {
          setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
        }
        setCardPropsData((prevData) => {
          return showGrid && page > 1
            ? [...prevData, ...convertToEserviceCard(eserviceData)]
            : convertToEserviceCard(eserviceData);
        });
        setIsFetching(false);
      }

      return;
    }

  }, [getEserviceList.status, allEservicesParams])

  useEffect(() => {
    if (selectedCardOption && selectedCardOption?.id) {
      const selectedEservice = cardPropsData.find((eservice) => eservice.id === selectedCardOption.id);
      if (selectedEservice && selectedCardOption.selectedValue === 'param') {
        const newPath = `/${profilePathName}/guichets/${selectedEservice.guichetId}/eservices/${selectedEservice.id}`;
        navigate(newPath);
      }
      if (selectedEservice && selectedCardOption.selectedValue === 'edit') {
        const newPath = `/studio/${selectedEservice.guichetId}/${selectedEservice.id}/${selectedEservice.moduleName}`;
        window.open(newPath, '_blank');
      }
      if (selectedEservice && selectedCardOption.selectedValue === 'access') {
        const newPath = `/karazal/kas/publish.jsp?id=/${selectedEservice.guichetId}`;
        window.open(newPath, '_blank');
      }
    }

  }, [selectedCardOption]);

  const BreadCrumbItems = [
    { icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}` },
    { title: "Catalogue", href: "" },
  ]
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 h-[100%] rounded-md">
      {contextHolder}
      <BreadCrumb items={BreadCrumbItems} />
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarSortBy={fBsEserv}
      />
      {showGrid && <>{(cardPropsData.length >= 1 || isFetching) ?
        <CardGrid isFetching={isFetching}
          setFavoriteEservice={setFavoriteRequest}
          currentPage={page}
          totalPages={Math.ceil(totalNumber / 10)}
          fetchMoreData={(nextPage: number) => {
            setPage(nextPage);
          }}
          items={cardPropsData}
          type="eservice"
          onSelectDropDown={setSelectedCardOption} /> :
        <EmptyData icon={emptyIcon} title={"Aucun e-service pour l'instant"}
          description={"La liste des e-services créés sera affichée ici"}
        />}</>}

      {showTable && (cardPropsData.length > 0 || isFetching ? <>
        {profilePathName == 'guichet' ? <Table
          isFitching={isFetching}
          setFavoriteEservice={setFavoriteRequest}
          type='eservice'
          setCurrentPage={(page) => setPage(page)}
          currentPage={page}
          totalItems={totalNumber}
          onSelectDropDown={setSelectedCardOption}
          headerItems={{
            items: ['', 'Nom', 'Source', 'Catégorie', 'Rôle', 'Modifié', 'Statut']
          }}
          rows={cardPropsData}
        />
          : <Table
            isFitching={isFetching}
            setFavoriteEservice={setFavoriteRequest}
            type='eserviceUsager'
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalNumber}
            onSelectDropDown={setSelectedCardOption}
            headerItems={{
              items: ['', 'Nom', 'Source', 'Catégorie']
            }}
            rows={cardPropsData}
          />}

      </>
        : <EmptyData icon={emptyIcon} title={"Aucun e-service pour l'instant"}
          description={"La liste des e-services créés sera affichée ici"}
        />)}

      {
        selectedCardOption && selectedCardOption.selectedValue === 'rejoindre' &&
        <Modal
          onClose={() => { setSelectedCardOption(null) }}
          title={"Demande d’accès"}
          leftBtnArgs={{
            label: 'Annuler',
            args: { onClick: () => setSelectedCardOption(null) },
            color: 'neutral',
            size: 'xs',
            styling: 'outline'
          }}
          rightBtnArgs={{
            label: 'Ajouter',
            args: { onClick: handleJoinEservice },
            color: 'primary',
            size: 'xs',
            styling: 'solid'
          }}
          icon={""}
        >
          <div className="flex flex-col p-6 gap-3">
            <span className={styles.textRequest}>Veuillez choisir le rôle que vous souhaitez occuper dans l&apos;e-service</span>
            <div className={styles.labelRole}>Rôle</div>
            <MultipleAdvancedDropDownList
              list={filteredRoleList}
              selectedValues={values}
              addNew={null}
              errorMessage={errorMessageJoin}
              handlerChange={onChange}
              size={3} label={undefined} />
            <TextArea size={"sm"}
              args={{
                placeholder: "Ajouter une note...",
                value: note,
                onChange: (e) => { setNote(e.target.value) }
              }}
              label="Note" />
          </div>
        </Modal>
      }
    </PageContentContainer>

  )
}
