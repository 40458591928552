import { useState, useEffect, FC } from "react";
import FilterSvg from "../../../../../shared/components/icons/FilterSvg";
import { FilterBar } from "../../../components/ui/filter-bar/FilterBar"
import { FilterBarContentProps } from "../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../components/ui/filter-bar/FilterBarInput";
import ListSvg from "../../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../../shared/components/icons/GridSvg";
import { PageContentContainer } from "../../../layouts";
import { Administrator } from "../../../components/ui/cards/base-cards/CardAdministrators";
import { BreadCrumb, EmptyData } from "../../../../../shared/components";
import { MaterialIcon } from "../../../../../shared/components/icons";
import { FilterBarSortByProps } from "../../../components/ui/filter-bar/FilterBarSortBy";
import { useHandleDomLocation } from "../../../hooks";
import CheckCircle from "../../../../../shared/components/icons/CheckCircle";
import ScheduleSend from "../../../../../shared/components/icons/ScheduleSend";
import { CardGrid } from "../../../components/ui/card-grid/CardGrid";
import { Table } from "../../../components/ui/table/Table";
import { AdministrateusPageForm, AdministrateusPageRequest } from "../../../types/data.types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../shared/stores/store";
import { useParams } from "react-router-dom";
import { Eservice } from "../../../components/ui/cards/base-cards/CardEservice";
import { useGetRequestJoinAdministrateusPage } from "../../../api/window/caller";
import styles from "./administratorss-pages.module.css"
import { Modal } from "kls-ui";
import { useForm } from "react-hook-form";
import { AdministrateusPageSchema } from "../../../holders/schemas/administrateus.schemas";
import { InputText, Select } from "../../../../../shared/components/controlled-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { DynamicListData } from "../../../../../shared/types";
import { inviteAdministrator } from "../../../api/eservice/caller";

interface ApiData { industriesList: DynamicListData[] | undefined; }

interface ApiStatus { industriesList: boolean | undefined; }

interface AdministrateusPageProps {
  rootClassName?: string;
  isError?: boolean | undefined;
  backButton?: { label: string, onClick: () => void }
  isLoading: ApiStatus;
  isSuccess?: ApiStatus
  data: ApiData;
}

export const AdministrateusPage: FC<AdministrateusPageProps> = (props) => {
  const { profilePathName } = useHandleDomLocation()
  const [searchValue, setSearchValue] = useState<string>('');
  const [approved, setApproved] = useState<boolean>(false);
  const [invitations, setInvitations] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{ [key: string]: string | undefined; }>({ value: 'recent', label: 'Récent' });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedCardOption, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [cardPropsData, setCardPropsData] = useState<Administrator[]>([]);
  const [initialData, setInitialData] = useState<Administrator[]>([]);
  const [role, setRole] = useState<string>('');
  const [activation, setActivation] = useState<string>('');
  console.log(role, activation)
  const [showModal, setShowModal] = useState(false);
  const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
  const { eserviceID } = useParams<{ eserviceID: string }>();
  const id = (selectedEservice && (selectedEservice as Eservice).id) ? (selectedEservice as Eservice).id : eserviceID || '';
  const getRequestJoinAdministrateusPage = useGetRequestJoinAdministrateusPage({ EserviceId: id });
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
  const updateAdministrateusPage = inviteAdministrator();

  const { control, handleSubmit, formState: { errors } } = useForm<AdministrateusPageForm>({ resolver: yupResolver(AdministrateusPageSchema) });

  const renderindustriesOptions = () => {
    if (!props.data || !props.data.industriesList) {
      return [];
    }

    return props.data.industriesList.map((item: DynamicListData) => ({
      ...item,
      icon: <i className="material-flex-icon text-lg text-blue-600">{item.icon}</i>,
    }));
  };

  const handleSubmitAdministrateusPageSettings = (data: AdministrateusPageForm) => {
    updateAdministrateusPage.mutate(data);
  }

  const onSubmit = (formData: AdministrateusPageForm) => {
    handleSubmitAdministrateusPageSettings({
      ...formData, EserviceId: eserviceID
    });
  };
  const rolesList = [
    { value: 'SUPER_ADMINISTRATEUR', label: 'Super_administrateur' },
    { value: 'PROPRIETAIRE', label: 'Propriétaire' },
  ];

  const activationList = [
    { value: 'ACTIF', label: 'Actif' },
    { value: 'INACTIF', label: 'Inactif' },
  ];

  useEffect(() => {
    if (getRequestJoinAdministrateusPage.isSuccess && getRequestJoinAdministrateusPage.data && getRequestJoinAdministrateusPage.data.status != 401) {
      const administrateusPageData = getRequestJoinAdministrateusPage.data;
      if (administrateusPageData) {
        console.log('eservicesData data', administrateusPageData.body.data);
        const transformedData: Administrator[] = administrateusPageData.body.data.map((item: AdministrateusPageRequest) => ({
          id: String(item.userId),
          email: item.emailPersonnel || '',
          firstName: item.nameUser || '',
          color: "var(--blue-700)",
          profilePicture: item.logoUser || '',
          roleDetails: {
            role: item.roleUserDemanded || '',
            status: item.roleStatusUser || '',
            roleType: item.statusRequest || ''
          }
        }));

        setInitialData(transformedData);
        setCardPropsData(transformedData);
      }
      return;
    }
  }, [getRequestJoinAdministrateusPage.fetchStatus]);


  useEffect(() => {
    let filteredData = [...initialData];

    if (approved && !invitations) {
      filteredData = filteredData.filter(item => item.roleDetails?.roleType === "Acceptée");
    }

    if (invitations && !approved) {
      filteredData = filteredData.filter(item => item.roleDetails?.roleType === "Brouillon");
    }

    if (searchValue.trim() !== '') {
      filteredData = filteredData.filter(item =>
        item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.email.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (role) {
      filteredData = filteredData.filter(item =>
        item.roleDetails?.role?.toUpperCase() === role.toUpperCase()
      );
    }

    if (activation) {
      filteredData = filteredData.filter(item =>
        item.roleDetails?.status?.toUpperCase() === activation.toUpperCase()
      );
    }


    setCardPropsData(filteredData);
    setTotalNumber(filteredData.length);
    setIsFetching(false);
  }, [approved, invitations, searchValue, role, activation, initialData]);

  const fBsAdminList: FilterBarSortByProps = {
    items: [
      {
        data: rolesList,
        label: 'Rôle(s)',
        placeHolder: 'Rôle(s)',
        selectedValue: role,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            setRole(e);
          }
        }
      },
      {
        data: activationList,
        label: 'Activation',
        placeHolder: 'Activation',
        selectedValue: activation,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            setActivation(e);
          }
        }
      },]
  };

  const handleAddAdminisitrateusClick = () => {
    console.log("Add Event");
    setShowModal(true);
  };
  const fBc: FilterBarContentProps = {
    label: 'Administrateurs', searchLength: totalNumber,
    buttons: [{
      icon: CheckCircle, label: 'Approuvés', isSelected: approved,
      handleClick: () => {
        if (page > 1) {
          setPage(1);
        }
        setApproved((prevApproved) => !prevApproved);
        setInvitations(false);
      }
    },
    {
      icon: ScheduleSend, label: 'Invitations', isSelected: invitations,
      handleClick: () => {
        if (page > 1) {
          setPage(1);
        }
        setInvitations((prevInvitations) => !prevInvitations);
        setApproved(false);
      }
    }], buttonAddArgs: {
      label: 'Ajouter administrateur',
      args: { onClick: handleAddAdminisitrateusClick },
      color: "primary",
      styling: "ghost",
      size: "sm",
      leftIcon: <MaterialIcon label="add" />
    },
  };

  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    ...(approved && {
      filterButton: {
        handleClick: (showFilters) => {
          setShowFilters(!showFilters);
        },
        icon: FilterSvg,
        label: 'Filtres',
        selected: showFilters,
      },
    }),
    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [{ value: 'popular', label: 'Populaire' }, { value: 'recent', label: 'Récent' }],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        } setCardPropsData([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      } setCardPropsData([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue.trim() !== '') {
        if (page > 1) {
          setPage(1);
        } setCardPropsData([]);
        setSearchValue('');
      }
    },
    onSearch: (e) => {
      if (page > 1) {
        setPage(1);
      }
      const filteredData = initialData.filter(item =>
        item.firstName.toLowerCase().includes(e.toLowerCase()) ||
        item.email.toLowerCase().includes(e.toLowerCase())
      );
      setSearchValue(e);
      setCardPropsData(filteredData);
    }
    ,
    listButton: {
      handleClick: (showTable) => {
        if (showTable) { return; } setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) { return; } setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
  };

  const handleOpenModal = () => setShowModal(prevState => !prevState);



  const BreadCrumbItems = [{ icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}` }, { title: "Administrateurs", href: "" }]

  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 rounded-md">
      <BreadCrumb items={BreadCrumbItems} />
      {(selectedCardOption?.selectedValue !== 'param') && (
        <>
          <FilterBar filterBarContent={fBc} filterBarInput={fBi} filterBarSortBy={fBsAdminList} />
          {/* <Button label="Ajout administrateur" size={"sm"} color={"secondary"} styling={"solid"} onClick={handleOpenModal} /> */}
          {showModal && (
            <Modal
              onClose={handleOpenModal}
              width="950px"
              title={"Ajout administrateur"}
              leftBtnArgs={{
                label: 'Annuler',
                args: { onClick: handleOpenModal },
                color: 'neutral',
                size: 'xs',
                styling: 'outline'
              }}
              rightBtnArgs={{
                label: 'Ajouter',
                args: { onClick: handleSubmit(onSubmit) },
                color: 'primary',
                size: 'xs',
                styling: 'solid'
              }}
              icon={""}
            >
              <div className={styles.formDetails}>
                <InputText withError control={control} size="lg" label="Email" placeholder="Saisissez l’adresse email d’utilisateur" name="email" error={errors.email?.message} errorMessage={errors.email?.message} />
                <Select showSelectedIcon size="lg" label={"Rôle"} placeholder={""} options={renderindustriesOptions() || []} alwaysClose name="role" selectedValues={control._defaultValues.role} control={control} />
              </div>
              {/* <div className={styles.actions}>
                <Button label="Annuler" size={"lg"} color={"neutral"} styling={"outline"} />
                <Button label="Ajouter" size={"lg"} color={"secondary"} styling={"solid"} onClick={handleSubmit(onSubmit)} />
              </div> */}
            </Modal>
          )}
          {(showGrid || showTable) && (cardPropsData.length === 0 && !isFetching) ? (<EmptyData icon={emptyIcon} title={"Aucun administrateus pour l'instant"} description={"La liste des administrateus créés sera affichée ici"} />) : showGrid && (cardPropsData.length >= 1 || isFetching) ?
            (<CardGrid isFetching={isFetching} currentPage={page} totalPages={Math.ceil(totalNumber / 10)} fetchMoreData={(nextPage: number) => { setPage(nextPage); }} items={cardPropsData} type="administrateus" onSelectDropDown={setSelectedCardOption} />) : showTable && (cardPropsData.length > 0 || isFetching) ?
              (<Table isFitching={getRequestJoinAdministrateusPage.isFetching} type="administrateus" setCurrentPage={setPage} currentPage={page} totalItems={totalNumber} onSelectDropDown={setSelectedCardOption} headerItems={{ items: ['Administrateur', 'Login', 'Rôle', 'Activation'] }} rows={cardPropsData} />) : null}
        </>
      )}
    </PageContentContainer>
  );
}
