import { FC, useState } from 'react';
import { Card } from '../Card';
import styles from '../cards.module.css';
import classNames from 'classnames';
import { Star } from '@phosphor-icons/react';
import { IconSize } from '../../../../../../shared/utils/enums';
import logo from '../../../../assets/icons/logo-app.svg';
import logoKaraz from '../../../../assets/icons/karaz-logo.svg';
import VerifiedSvg from '../../../../../../shared/components/icons/VerifiedSvg';
import TimeSvg from '../../../../../../shared/components/icons/TimeSVG';
import { Tooltip } from 'antd';
import { formatDate } from '../../../../utils/helpers/validators.helper';
import { MaterialIcon } from '../../../../../../shared/components/icons';
import { Menu } from '../../../../../../shared/components';
import { FavoriteEserviceRequest } from '../../../../types';
import { useHandleDomLocation } from '../../../../hooks';
export interface CardEserviceProps {
  eservice: Eservice;
  cardAction: CardActionProps;
  dropDownData?: {label:string,value:string,icon:JSX.Element}[];
  setFavoriteEservice?:(params:FavoriteEserviceRequest)=>void;
  isSelected?:boolean;
  onSelect?:(item:Eservice, isSelected: boolean)=>void;
  icon?:string;
}
export interface Eservice {
  id: string;
  name: string;
  industry: string;
  role: string[];
  status: string;
  verification: string;
  favorite: boolean;
  imageUrl: string;
  guichetName: string;
  dateModif: string;
  isKaraz: boolean;
  isPublic: string;
  guichetId:string;
  moduleName:string;
}
interface CardActionProps {
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
}
export const CardEservice: FC<CardEserviceProps> = (props) => {
  const [favorite, setFavorite] = useState<boolean>(props.eservice.favorite);
  const { profilePathName } = useHandleDomLocation();

  const cardRoleStyle = classNames({
    [styles.cardRole]: true
  });
  const cardIsPublicStyle = classNames({
    [styles.cardTitleSvg]: props.eservice.isPublic,
    [styles.notPublic]: !props.eservice.isPublic
  });
  const handleSelect = (value: string) => {
    if (props.cardAction?.onSelectDropDown) {
      props.cardAction.onSelectDropDown({
        id: props.eservice.id,
        selectedValue: value
      });
      return;
    }
  };
  const handleFavoriteIconClick = () => {
    if (props.setFavoriteEservice) {
      setFavorite(!favorite);
      props.setFavoriteEservice({
        idEservice: props.eservice.id,
        favorite: !favorite
      });
    }
  };
  const handleSelectChange = () => {
    if (props.onSelect)
    props.onSelect(props.eservice, !props.isSelected);
  };

  const renderStatus = () =>{
    if(profilePathName === 'guichet'){
    if(props.eservice.role && props.eservice.status) {
      return (
        <span className={styles.publicCard}>
          {props.eservice.status}
          {renderStatusIcon()}
        </span>
    );
    }
    }
    return null;
  }
  const renderFavoriteIcon = () => {
    return (
      <span
        onClick={(e) => {
          handleFavoriteIconClick();
          e.stopPropagation();
        }}>
        {favorite && (
          <div className="flex items-center">
            <Star
              size={16}
              weight="fill"
              className="text-amber-400 stroke-2 stroke-amber-400"
            />
          </div>
        )}
        {!favorite && (
          <div className={`flex items-center ${styles.headerIcons}`}>
            <Star
              size={16}
              weight="fill"
              className="text-slate-200 stroke-2 stroke-slate-100"
            />
          </div>
        )}
      </span>
    );
  };
  const renderStatusIcon = () => {
    if (props.eservice.isPublic != undefined) {
      return (
        <span className={`material-icon ${cardIsPublicStyle}`}>
          {props.eservice.isPublic == 'Publish' ? 'visibility' : 'visibility_off'}
        </span>
      );
    }
    return null;
  };
  const [hoveredName, setHoveredName] = useState(false);
  const handleHoverNameChange = (open: boolean) => {
    setHoveredName(open);
  };
  const [hovered, setHovered] = useState(false);
  const handleHoverChange = (open: boolean) => {
    setHovered(open);
  };
    const renderDateIcon = () => {
    return (props.eservice.dateModif && profilePathName == 'guichet') ? (  <div className='cursor-pointer'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Tooltip   open={hovered}
      onOpenChange={handleHoverChange} placement="bottom" title={formatDate(props.eservice.dateModif)} color='var(--ant-tooltip-background)'>
      <TimeSvg size={IconSize.PX14} className={styles.cardTitleSvg} /></Tooltip></div>
    ) : null;
  };
  const renderVerificationIcon = () => {
    if (props.eservice.verification) {
      return (
        <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
      );
    }
    return null;
  };
  const menuIcon = (
    <MaterialIcon label="more_horiz" className={styles.menuIcon} />
  );
  console.log("drop",props.dropDownData)
  const renderMenu = () => {
    if (props.dropDownData && !props.onSelect) {
      return (
        <Menu
          icon={menuIcon}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={props.dropDownData}
        />
      );
    }
    return null;
  };

  return (
    <Card onClick={handleSelectChange} selected={props.isSelected}
      childrenCard={
        <>
          <div className={styles.starFContainer}>
            {renderFavoriteIcon()}
            <div className={styles.headerIcons}>{renderMenu()}</div>

          </div>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={props.eservice.imageUrl || logo}
            />
          </div>
          <div className={styles.industryContainer}>
            <div>
              {props.eservice.isKaraz && (
                <img className={styles.imageKaraz} src={logoKaraz} />
              )}
            </div>
            {props.eservice.role && (
              <span className={cardRoleStyle}>{props.eservice.role.join('/')}</span>
            )}
            <span className={styles.industry}>
            <i className="material-flex-icon text-lg">{props.icon}</i>
            </span>
          </div>
        </>
      }
      childrenInfo={
        <>
          <div className={styles.cardnameTime}>
            <div className='flex items-center justify-center gap-1 cursor-pointer' >
            <div className={styles.cardTitle}
                 onMouseEnter={() => setHoveredName(true)}
                onMouseLeave={() => setHoveredName(false)}
    >
      <Tooltip   open={hoveredName}
      onOpenChange={handleHoverNameChange} placement='bottomLeft' title={props.eservice.name} color='var(--ant-tooltip-background)'>
   </Tooltip> 
   <span >
              {props.eservice.name}
              {renderVerificationIcon()}
            </span>
            
            </div>{renderDateIcon()}</div>
           
          {renderStatus()}
          </div>
          <div>
            {props.eservice.guichetName && (
              <>
                <span className={styles.cardGuichetName}>
                  <span className={styles.cardProp}>Propriété de </span>
                  <span>{props.eservice.guichetName}</span> 
                  <VerifiedSvg
                    size={IconSize.PX14}
                    className={styles.cardTitleSvg}
                  />
                </span>
              </>
            )}
          </div>
        </>
      }
    />
  );
};
