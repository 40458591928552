import FilterSvg from "../../../../../../../shared/components/icons/FilterSvg";
import { FilterBar } from "../../../../../components/ui/filter-bar/FilterBar";
import { FilterBarContentProps } from "../../../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../../../components/ui/filter-bar/FilterBarInput";
import { fBsEserv } from "../../../../../mockdata/MockDataFilterBar";
import { Table } from "../../../../../components/ui/table/Table";
import { EmptyData } from "../../../../../../../shared/components";
import { ESERVICE_WIDGET_MOCK_DATA } from "../../../../../mockdata/MockDataMember";
import { useState } from "react";


export const EserviceUsersTab = () => {
const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
const [page, setPage] = useState<number>(1);

    const fBc: FilterBarContentProps = {
        label: 'Utilisateurs',
        searchLength: 9,
        buttons: []
    }


    const fBi: FilterBarInputProps = {
        filterBarInputId: 'search',
        filterBarInputPlaceHolder: 'Rechercher',
        filterBarInputType: 'text',
        filterBarInputValue: '',
        filterButton: {
          handleClick: () => {
          },
          icon: FilterSvg,
          label: 'Filtres',
          selected: false
        },
    
        isGridDisplayMode: false,
       
        onChange: () => {
          
        },
        onClear: () => {
          
        },
        onSearch: () => {
         
        },
       
        
      };

    return(
          <div className="flex flex-col gap-7 p-5 h-[100%]">
<FilterBar
      filterBarContent={fBc}
      filterBarInput={fBi}
      filterBarSortBy={fBsEserv}
    />
{ESERVICE_WIDGET_MOCK_DATA.length < 1 ? <EmptyData
                            icon={emptyIcon}
                            title={"Aucun pour l'instant"}
                            description={"La liste sera affichée ici"}
                        /> : 
                                <Table
                    type="users"
                    setCurrentPage={(page) => setPage(page) }
                    currentPage={page}
                    headerItems={{
                        items: ['Utilisateur', '', 'login']
                    }}
                    rows={ESERVICE_WIDGET_MOCK_DATA} 
                    totalItems={9}    
                    />
                }           
</div>);
}