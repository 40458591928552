import { useEffect, useState } from 'react';
import ToggleSwitch from '../../../../shared/components/switcher/ToggleSwitch';
import styles from './profileCard.module.css';
import classNames from 'classnames';
import { MaterialIcon } from '../../../../shared/components/icons';
import { AvatarComponent } from '../../../../shared/components/avatar/AvatarComponent';
import { Tooltip } from 'antd';

interface ProfileCardProps {
  imageUrl: string | undefined;
  name: string | undefined;
  email: string | undefined;
  version?: string;
  signOut: () => void;
  className?: string;
}

export const ProfileCard = (props: ProfileCardProps) => {
  const [theme, setTheme] = useState(
    localStorage.getItem('data-theme') !== null
      ? localStorage.getItem('data-theme')
      : 'light'
  );
  const toggleTheme = (isDark: boolean) => {
    const newTheme = !isDark ? 'light' : 'dark';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('data-theme', newTheme);
  };
  const [hoveredName, setHoveredName] = useState<{
    isOpen: boolean;
    value: string;
  }>({ isOpen: false, value: '' });
  const handleHoverNameChange = (open: boolean) => {
    setHoveredName((prevData) => {
      return { ...prevData, isOpen: open };
    });
  };
  useEffect(
    () =>
      toggleTheme(
        localStorage.getItem('data-theme') !== null &&
          (localStorage.getItem('data-theme') !== 'dark' ? false : true)
      ),
    []
  );
  const { name, email, version, signOut, className = '' } = props;
  const elements = [
    {
      label: 'Paramètres',
      icon: (
        <i
          style={{ color: 'var(--mapping-slate-500)' }}
          className={'material-icon'}>
          settings
        </i>
      ),
      onClick: () => {}
    },
    {
      label: 'Se déconnecter',
      icon: <MaterialIcon label="logout" className="text-red-600" />,
      onClick: signOut
    }
  ];
  const rootStyle = classNames({
    [styles.profileCard]: true,
    [className]: true
  });
  return (
    <div className={rootStyle}>
      <div className={styles.profileInfo}>
        {props.imageUrl ? (
          <div className="rounded-full w-full h-full flex items-center justify-center ">
            <img
              src={props.imageUrl}
              alt=""
              className="aspect-square size-8 rounded-full"
            />
          </div>
        ) : (
          <AvatarComponent fullname={props.name || ''} />
        )}
        <div className="flex flex-col justify-center items-start ">
          <span
            className={styles.profileCardTitle}
            onMouseEnter={() =>
              setHoveredName({ isOpen: true, value: name ? name : '' })
            }
            onMouseLeave={() => setHoveredName({ isOpen: false, value: '' })}>
            {name}
          </span>
          <span
            className={styles.profileCardDescription}
            onMouseEnter={() =>
              setHoveredName({ isOpen: true, value: email ? email : '' })
            }
            onMouseLeave={() => setHoveredName({ isOpen: false, value: '' })}>
            {email}
          </span>
          <Tooltip
            open={hoveredName.isOpen}
            onOpenChange={(open: boolean) => handleHoverNameChange(open)}
            placement="bottomLeft"
            title={hoveredName.value}
            color="var(--ant-tooltip-background)"></Tooltip>
        </div>
        <div></div>
      </div>
      <div className={styles.profileList}>
        {elements.map((element, index) => (
          <div
            key={index}
            className={styles.profileCardElement}
            onClick={element.onClick}>
            {element.icon}
            <span className={styles.elementLabel}>{element.label}</span>
          </div>
        ))}
      </div>
      <div className="w-full">
        <ToggleSwitch
          defaultValue={theme !== 'dark' ? false : true}
          label="Mode"
          onToggle={toggleTheme}
        />
      </div>
      <div className={styles.version}>{version}</div>
    </div>
  );
};
