import { FC } from 'react';
import { RoleTagProps, UserCard } from '../../user-card/UserCard';

export interface CardAdministratorProps {
  administrator: Administrator;
  cardAction: CardActionProps;
  dropDownData?: { label: string, value: string, icon: JSX.Element }[];
}

export interface Administrator {
  id: string;
  email: string;
  profilePicture?: string;
  firstName: string;
  color: string;
  roleDetails?: RoleTagProps;
}
interface CardActionProps {
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
}
export const CardAdministrators: FC<CardAdministratorProps> = (props) => {
  

  return (
    <UserCard   
    type={props.administrator.roleDetails?.roleType === 'ACCEPT' ? 'memberApprouve' : 'memberNonApprouve'}
    color={props.administrator.color} 
    firstName={props.administrator.firstName} 
    email={props.administrator.email} 
    profilePicture={props.administrator.profilePicture ?? ""} 
    role={props.administrator.roleDetails} />
  );
};
