import { FC, useEffect, useState } from "react";
import { ListEserviceRequest } from "../../../../types";
import { useAddEserrviceToEshop, useGetCategoriesList, useGetEserviceList } from "../../../../api";
import { Eservice } from "../../../../components/ui/cards/base-cards/CardEservice";
import { useParams } from "react-router-dom";
import { FilterBarSortByProps } from "../../../../components/ui/filter-bar/FilterBarSortBy";
import { FilterBarInputProps } from "../../../../components/ui/filter-bar/FilterBarInput";
import FilterSvg from "../../../../../../shared/components/icons/FilterSvg";
import ListSvg from "../../../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../../../shared/components/icons/GridSvg";
import { convertToEserviceCard } from "../../../../api/eservice/formatteur";
import { EmptyData, SimpleModal } from "../../../../../../shared/components";
import { Table } from "../../../../components/ui/table/Table";
import { FilterBar } from "../../../../components/ui/filter-bar/FilterBar";
import { CardGrid } from "../../../../components/ui/card-grid/CardGrid";
import { MessageInstance } from "antd/es/message/interface";

interface EserviceAddEshopProps {
        isOpen:boolean;
    onCancel:()=>void;    
    messageApi: MessageInstance;

}
export const EserviceAddEshopModal : FC<EserviceAddEshopProps> = (props)=>{
    const [allEservicesParams, setAllEservicesParams] =
    useState<ListEserviceRequest>({
      numPage: 1,
      pageSize: 10,
      contributeur: false,
      status: 'Public',
      reference: '',
      favorite: false,
      industry: '',
      entity: '',
      guichetName: '',
      idGuichet: '',
      typePublication: 'Publish',
      role: '',
      Fields: 'ALL'
    });
    const getCategoriesList = useGetCategoriesList();
    const [sortBy, setSortBy] = useState<{
        [key: string]: string | undefined;
      }>({ value: 'recent', label: 'Récent' });
    const [searchValue, setSearchValue] = useState<string>('');
    const [cardPropsData, setCardPropsData] = useState<Eservice[]>([]);
    const { guichetId } = useParams<{ guichetId: string }>();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [totalNumber, setTotalNumber] = useState(0);
    const [reset, setReset] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [selectedItems, setSelectedItems] = useState<Array<Eservice>>([]);
    const getEserviceList = useGetEserviceList(allEservicesParams);
    const [showTableEshop, setShowTableEshop] = useState<boolean>(false);
    const [showGridEshop, setShowGridEshop] = useState<boolean>(true);
    const [industry, setIndustry] = useState<string>('');
    const postAddEservicesToEshop = useAddEserrviceToEshop();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>

    const fBsEservEshop: FilterBarSortByProps = {
        items: [
          {
            data: getCategoriesList.customHandler.data,
            label: 'Catégorie(s)',
            placeHolder: 'Catégorie(s)',
            multiple: true,
            clearable: true,
            selectedValue: industry,
            onChange: (e: string) => {
              {
                if (page > 1) {
                  setPage(1);
                }
                if (e == industry) return;
                setCardPropsData([]);
                setIndustry(e);
              }
            }
    
          },    
    
        ]
      };

        const fBi: FilterBarInputProps = {
            filterBarInputId: 'search',
            filterBarInputPlaceHolder: 'Rechercher',
            filterBarInputType: 'text',
            filterBarInputValue: searchValue,
            filterButton: {
              handleClick: (showFilters) => {
                setShowFilters(!showFilters);
              },
              icon: FilterSvg,
              label: 'Filtres',
              selected: showFilters,
              reset: reset,
              resetClick: () => {
                setIndustry('');
                setReset(false);
              }
            },
        
            isGridDisplayMode: false,
            itemsFilterBarSort: {
              label: 'Trier par:',
              options: [
                {
                  value: 'popular',
                  label: 'Populaire'
                },
                {
                  value: 'recent',
                  label: 'Récent'
                }
              ],
              selectedValue: sortBy,
              setSelectedValue: (sortBy) => {
                if (page > 1) {
                  setPage(1);
                }
                setCardPropsData([]);
                setSortBy(sortBy);
              }
            },
            onChange: (e) => {
              if (page > 1) {
                setPage(1);
              }
              setCardPropsData([]);
              setSearchValue(e);
            },
            onClear: () => {
              if (searchValue == '') return;
              if (page > 1) {
                setPage(1);
              }
              setCardPropsData([]);
              setSearchValue('');
            },
            onSearch: (e) => {
              if (searchValue == '') return;
              if (page > 1) {
                setPage(1);
              }
              setCardPropsData([]);
              setSearchValue(e);
            },
              listButton: {
                handleClick: (showTableEshop) => {
                  if (showTableEshop) {
                    return;
                  }
                  setShowGridEshop(false);
                  setShowTableEshop(!showTableEshop);
                },
                icon: ListSvg,
                selected: showTableEshop
              },
              gridButton: {
                handleClick: (showGridEshop) => {
                  if (showGridEshop) {
                    return;
                  }
                  setShowTableEshop(false);
                  setShowGridEshop(!showGridEshop);
                },
                icon: GridSvg,
                selected: showGridEshop
              },
            }

        
    const handleAddEserviceToEshop = () => {
        if (guichetId) {
          postAddEservicesToEshop.mutate({
            idGuichet: guichetId,
            idEservices: selectedItems.map(e => e.id).join(','),
          },
            {
              onSuccess: () => {
                props.messageApi.success({
                  content: 'E-service(s) ajouté(s) avec succés ',
                  duration: 2.5
                });
               handleCancel();
              }
            });
        }
    
      }
      const handleCancel =()=>{
        setSelectedItems([]);
        props.onCancel();

      }

      useEffect(() => {
        if ( industry) {
          setReset(true)
        } else {
          setReset(false)
        }
        setIsFetching(true);
        setAllEservicesParams((prevData) => ({
          ...prevData,
    
          recent: sortBy.value,
          name: searchValue,
          industry: industry,
          numPage: page,
        }));
    
    
    
      }, [searchValue, page, industry]);

      
      useEffect(() => {
        if (getEserviceList.isFetching) {
          setIsFetching(true);
        }
        if (getEserviceList.isSuccess) {
          const eserviceData = getEserviceList.data.body.data;
          const totalItems = getEserviceList.data.body.pagination.totalLength;
    
          if (eserviceData) {
            if (totalItems !== undefined) {
              setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
            }
            setCardPropsData((prevData) => {
              console.log("eservices", eserviceData)
              return showGridEshop && page > 1
                ? [...prevData, ...convertToEserviceCard(eserviceData)]
                : convertToEserviceCard(eserviceData);
            });
            setIsFetching(false);
          }
    
          return;
        }
    
      }, [getEserviceList.status, allEservicesParams, getEserviceList.fetchStatus]);

    return(
        <SimpleModal
        type="primary"
        cancelLabel="Annuler"
        validateLabel="Ajouter"
        isOpen={props.isOpen}
        onValidate={handleAddEserviceToEshop}
        onCancel={handleCancel} 
        afterClose={handleCancel}
        title={"E-services"}
       >
        <div className="flex flex-col gap-5 px-2 h-[20rem]">

          <FilterBar filterBarInput={fBi} filterBarSortBy={fBsEservEshop} />
          {showGridEshop && (
            <>
              {(cardPropsData.length >= 1 || isFetching) ? (
                <CardGrid
                  isFetching={isFetching}
                  currentPage={page}
                  totalPages={Math.ceil(totalNumber / 10)}
                  fetchMoreData={(nextPage: number) => {
                    setPage(nextPage);
                  }}
                  items={cardPropsData}
                  type="selectable"
                  onSelectDropDown={()=>{}}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              ) : (
                <EmptyData
                  icon={emptyIcon}
                  title={"Aucun e-service pour l'instant"}
                  description={"La liste des e-services créés sera affichée ici"}
                />
              )}
            </>
          )}

          {showTableEshop && (
            cardPropsData.length > 0 || isFetching ? (
              <Table
                isFitching={isFetching}
                type='selectable'
                setCurrentPage={setPage}
                currentPage={page}
                totalItems={totalNumber}
                headerItems={{
                  items: ['', 'Nom', 'Source', 'Catégorie']
                }}
                rows={cardPropsData}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />
            ) : (
              <EmptyData
                icon={emptyIcon}
                title={"Aucun e-service pour l'instant"}
                description={"La liste des e-services créés sera affichée ici"}
              />
            )
          )}

        </div>
      </SimpleModal>);
}