import {useState, useEffect, useRef, ReactNode, FC} from 'react';
import styles from './tabs.module.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from "classnames";
interface Tab {
    name: string;
    label: JSX.Element;
    children: ReactNode;
}

interface TabsProps {
    defSelected?: string;
    tabs: Tab[];
    current?: string;
    align?: 'horizontal' | 'vertical';
    overflow?:boolean;
    className?: string;
}

export const Tabs: FC<TabsProps> = ({
                                        defSelected,
                                        tabs,
                                        current,
                                        align,
                                        overflow,
                                        className="",
                                    }) => {
    const [selected, setSelected] = useState(defSelected || tabs[0].name);
    const tabsRef = useRef<HTMLDivElement | null>(null);
    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

    useEffect(() => {
        if (current) setSelected(current);
    }, [current]);

    const checkScrollPosition = () => {
        if (tabsRef.current) {
            setCanScrollLeft(tabsRef.current.scrollLeft > 0);
            setCanScrollRight(
                tabsRef.current.scrollWidth >
                tabsRef.current.clientWidth + Math.ceil(tabsRef.current.scrollLeft)
            );
        }
    };

    useEffect(() => {
        checkScrollPosition();
        window.addEventListener('resize', checkScrollPosition);
        tabsRef.current?.addEventListener('scroll', checkScrollPosition);
        return () => {
            window.removeEventListener('resize', checkScrollPosition);
            tabsRef.current?.removeEventListener('scroll', checkScrollPosition);
        };
    }, []);

    const scroll = (direction: 'left' | 'right') => {
        if (tabsRef.current) {
            const scrollAmount = direction === 'left' ? -100 : 100;
            tabsRef.current.scrollBy({ top: 0, left: scrollAmount, behavior: 'smooth' });
        }
    };
   const contentStyles=classNames({
       "px-6 py-4":false,
       [className]:true,
       ["overflow-y-auto"]:overflow,
   })
    return (
        <div>
        <div className={styles.tabsContainer}>
            {canScrollLeft && (
                <button className={styles.arrow} onClick={() => scroll('left')}>
                    <ChevronLeftIcon className={styles.chevronLeftIcon}/>
                </button>
            )}
            <div
                className={`${align === 'vertical' ? styles.tabsKlsVertical : styles.tabsKls}`}
                ref={tabsRef}
            >
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        onClick={() => setSelected(tab.name)}
                        className={`${styles.tab} ${selected === tab.name ? styles.selected : ''}`}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            {canScrollRight && (
                <button className={styles.arrow} onClick={() => scroll('right')}>
                    <ChevronRightIcon className={styles.chevronRightIcon}/>
                </button>
            )}
        </div>
            <div className={contentStyles}>
                {tabs.find((tab: Tab) => tab.name == selected)?.children}
            </div>

            </div>
    );
};
