import { GenericResponse } from "../../../../shared/types";
import { GenericResponseWrapper, PageResponse } from "../../../../shared/types/api.type";
import { HttpMethods } from "../../../../shared/utils/enums";
import { fetchGenericJaxRsRequestFromFormData } from "../../../../shared/utils/helpers";
import { EserviceData, ListEserviceRequest } from "../../types";
import { EserviceMarketPlaceData, EserviceMarketPlaceDetailsRequest, InstallEserviceMarketPlaceRequest, InstallEserviceResponse } from "../../types/data/eservice.types";
import { ESERVICE_MARKETPLACE_DETAILS_API_PATH, ESERVICE_MARKETPLACE_INSTALL_API_PATH, ESERVICE_MARKETPLACE_LIST_API_PATH } from "./apiPath";

export const getEservicesMarketPlaceList = (data:ListEserviceRequest): Promise<GenericResponse<PageResponse<EserviceData[]>>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_MARKETPLACE_LIST_API_PATH, HttpMethods.POST);
};
export const getEserviceMarketPlaceDetails = (data:EserviceMarketPlaceDetailsRequest): Promise<GenericResponseWrapper<PageResponse<EserviceMarketPlaceData>>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_MARKETPLACE_DETAILS_API_PATH, HttpMethods.POST);
};
export const installEserviceMarketPlace = (data:InstallEserviceMarketPlaceRequest): Promise<GenericResponse<InstallEserviceResponse>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_MARKETPLACE_INSTALL_API_PATH, HttpMethods.POST);
};