import { FC } from 'react';
import styles from './pubstates.module.css';
import { Button } from '../../../../../shared/components';

interface PubStatesCards {
  status: "Publié" | "Non Publié" | "Privé" | "Publique";
  type: "icon" | "color",
  value: "red" | "green" | "visibility_off" | "visibility";
  description?: string;
  actionText?: string;
  handleClick?: () => void;
  disabled?: boolean;
}

export const PubStates: FC<PubStatesCards> = ({ status, type, value, description, actionText, handleClick, disabled }) => {
  return (
    <div className={styles.pubStateDetails}>
      <div className={styles.pubStateHeader}>
        <div className={styles.icon}>
          {type == 'color' ? <span className={styles[value]}></span> : <span className={`material-icon ${styles[value]}`}>{value}</span>}
          <span className={styles.pubStateText}>{status}</span>
        </div>
        <span className={styles.pubStateActive}>{description}</span>
      </div>
      {actionText && (
        <Button disabled={disabled} label={actionText} size={'md'} color={'primary'} styling={'ghost'} onClick={handleClick} />
      )}
    </div>
  );
};
