
import { FC } from 'react';
import { BaseSVGIconProps } from '../../types/components.type';
import AbstractSVGIcon from './AbstractSVGIcon';
const ScheduleSend: FC<BaseSVGIconProps> = (props) => (
  <AbstractSVGIcon
    size={props.size}
    className={props.className}
    spin={props.spin}
    hidden={props.hidden}
    viewBox="0 0 14 14">
    <path d="M10.0006 5.33333C8.16057 5.33333 6.66724 6.82667 6.66724 8.66667C6.66724 10.5067 8.16057 12 10.0006 12C11.8406 12 13.3339 10.5067 13.3339 8.66667C13.3339 6.82667 11.8406 5.33333 10.0006 5.33333ZM11.1006 10.2333L9.66724 8.8V6.66667H10.3339V8.52667L11.5672 9.76L11.1006 10.2333Z" />
    <path d="M6.00057 5.33333L2.00057 4.33333V2.00667L7.9139 4.5C8.54057 4.18667 9.24724 4 10.0006 4C10.0672 4 10.1272 4.00667 10.1872 4.00667L0.667236 0V10.6667L5.3339 8.7C5.3339 8.68667 5.3339 8.68 5.3339 8.66667C5.3339 8.13333 5.42724 7.62667 5.5939 7.14667L2.00057 8.66V6.33333L6.00057 5.33333Z" />
  </AbstractSVGIcon>
);
export default ScheduleSend;
