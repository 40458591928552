import { ChangeEvent, FC, useState } from "react";
import { cleanAndFormatNameResource } from "../../../../utils/helpers/validators.helper";
import { MAX_CARACTER_NAME } from "../../../../utils/constants/helpers.constants";
import { useCreateEservice } from "../../../../api";
import { useParams } from "react-router-dom";
import { Input } from "kls-ui";
import { InputText, SimpleModal } from "../../../../../../shared/components";
import { MessageInstance } from "antd/es/message/interface";


interface EserviceCreationModalProps {
    guichetName:string;
    messageApi: MessageInstance;
    isOpen:boolean;
    onCancel:()=>void;

}


export const EserviceCreationModal :FC<EserviceCreationModalProps>=(props)=>{

    const [nomService, setNomService] = useState('');
    const [errorMessageCrea, setErrorMessageCrea] = useState('');
    const { guichetId } = useParams<{ guichetId: string }>();
    const postCreateEservice = useCreateEservice();
    const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setErrorMessageCrea('');
        const value = e.target.value;
        if (value?.length <= MAX_CARACTER_NAME) {
          setNomService(value);
        }
      }
      const handleCancel = ()=>{
        setNomService('');
        setErrorMessageCrea('');
        props.onCancel();
      }
    const handlecreation = () => {
        if (!nomService || nomService.trim().length == 0) {
          setErrorMessageCrea("Ce champ est requis");
          return;
        }
        if (nomService.length > 0) {
          if (guichetId) {
            postCreateEservice.mutate({
              idGuichet: guichetId,
              nameEservice: nomService,
              status: 'Brouillon',
              source: 'true',
              visibilite: 'Private Service'
            },
              {
                onSuccess: () => {
                 props.messageApi.success({
                     content: 'Crée avec succès',
                     duration: 2.5
                  });
                }
              });
          } else {
            console.error('guichetId is not available', guichetId);
          }
         handleCancel();
        }
      }
    return( <SimpleModal
        type="primary"
        cancelLabel="Annuler"
        validateLabel="Ajouter"
        isOpen={props.isOpen}
        onValidate={handlecreation}
        onCancel={handleCancel} 
        afterClose={handleCancel}
        title={"Nouveau e-service"}
       >
        <div className="flex flex-col px-2 min-w-[450px] gap-3">
          <Input
            errorMessage={errorMessageCrea}
            size="sm"
            label="Nom de e-service"
            helpText={`ID :${cleanAndFormatNameResource(nomService)}`}
            args={{
              placeholder: "Entrez le nom de votre e-service",
              value: nomService,
              onChange: handleNameChange
            }}
            charCounter={MAX_CARACTER_NAME}
          />
          <InputText
            label="Guichet"
            size="sm"
            value={props.guichetName}
            disabled
          />
        </div>
      </SimpleModal>);
}