import { SetStateAction, useEffect, useRef, useState } from "react";
import FilterSvg from "../../../../../../../shared/components/icons/FilterSvg";
import GridSvg from "../../../../../../../shared/components/icons/GridSvg";
import ListSvg from "../../../../../../../shared/components/icons/ListSvg";
import StarSvg from "../../../../../../../shared/components/icons/StarSvg";
import { FilterBar } from "../../../../../components/ui/filter-bar/FilterBar";
import { FilterBarContentProps } from "../../../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../../../components/ui/filter-bar/FilterBarInput";
import { EserviceMembersListRequest, EserviceMembersListResponse } from "../../../../../types";
import { Table } from "../../../../../components/ui/table/Table";
import { Modal, OtpForm } from "kls-ui";
import { EmptyData , NotificationCard, Select, SimpleModal  } from "../../../../../../../shared/components";
import { message } from "antd";
import TimeSvg from "../../../../../../../shared/components/icons/TimeSVG";
import { useAcceptRequestJoinEservice, useAnnulationInvitationEservice, useGetEserviceMembersList, useGetRequestJoinEserviceList, useRefuseRequestJoinEservice,useSendInvitation } from "../../../../../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../shared/stores/store";
import { useParams } from "react-router-dom";
import styles from '../eservice.param.module.css';
import { Eservice } from "../../../../../components/ui/cards/base-cards/CardEservice";
import { UserCard } from "../../../../../components/ui";
import { FilterBarSortByProps } from "../../../../../components/ui/filter-bar/FilterBarSortBy";
import { AvatarComponent } from "../../../../../../../shared/components/avatar/AvatarComponent";
import { MaterialIcon } from "../../../../../../../shared/components/icons/MaterialIcon";
import { useHandleTranslation } from "../../../../../../../shared/hooks/useHandleTranslation";
import { NotificationType } from "../../../../../../../shared/utils/enums/components.enum";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SendInvitationSchema } from "../../../../../holders/schemas/sendInvi.shemas";
import { InputText, Select as ControlledSelect } from "../../../../../../../shared/components/controlled-ui";
import { EserviceInvitationRequest } from "../../../../../types/data/eservice.types";
import { generateOtp } from "../../../../../../../shared/utils/helpers/components.helper";
import SendSVG from "../../../../../../../shared/components/icons/SendSVG";
export const EserviceMembresTab = () => {

  const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);
  const { eserviceID } = useParams<{ eserviceID: string }>();
  const id = (selectedEservice && (selectedEservice as Eservice).id) ? (selectedEservice as Eservice).id : eserviceID || '';
  const getRequestJoinEserviceList = useGetRequestJoinEserviceList({ EserviceId: id });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedRowOption, setSelectedRowOption] = useState<{ id: string; selectedValue: string; emailPersonnel: string; nameUser: string; logoUser: string; role: string; ownerLogin: string; note: string } | null>(null);
  const [totalNumber, setTotalNumber] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [requestMembersData, setRequestMembersData] = useState<EserviceMembersListResponse[]>([]);
  const [typeRequest, setTypeRequest] = useState('');
  const [selectedButton, setSelectedButton] = useState('approuve')
  const [statusRequest, setStatusRequest] = useState('ACCEPT');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [roleFiltre, setRoleFiltre] = useState<string>('');
  const [actifFiltre, setActifFiltre] = useState<string>('');
  const postAcceptJoinEservice = useAcceptRequestJoinEservice();
  const postRefuseJoinEservice = useRefuseRequestJoinEservice();
  const postAnnulationInvitaionEservice = useAnnulationInvitationEservice();

  const [messageApi, contextHolder] = message.useMessage();
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
  const acceptedRoleRef = useRef(selectedRowOption?.role || '');
  const [resetFilters, setResetFilters] = useState<boolean>(false);

  const { t: tO } = useHandleTranslation('window');

  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);

const errorMessageStyles = classNames({
  ['!my-4']: true,
  ['notShow']: !isErrorDisplayed, 
  ['show transition-all duration-500']: isErrorDisplayed 
});
  const [roleError, setRoleError] = useState("");
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorAnnulation, setErrorAnnulation] = useState<string>("");

  const postSendInvitation = useSendInvitation();
  const [otpValue, setOtpValue] = useState<string>('') ;
  const [otpCode,setOtpCode] = useState(generateOtp(6));

  const { 
    control, 
    handleSubmit, 
    formState: { errors } ,
    reset
  } = 
  useForm({
    resolver: yupResolver(SendInvitationSchema)
  });

  const handleAddMemberClick = () => {
    setShowAddMemberModal(true);
    setErrorMessage(''); 
    setIsErrorDisplayed(false); 
};

const handleCloseAddMemberModal = () => {
  setShowAddMemberModal(false);
  reset({ email: '', role: '' });
  setErrorMessage('');
  setIsErrorDisplayed(false);
};


  const handleAddMember = (formData: { email: string; role: string; }) => {
    const { email, role } = formData;
    setRoleError('');
    setErrorMessage('');

    let hasError = false;

   if (email && role) {
    const data: EserviceInvitationRequest = {
      roles: [role],
      EserviceId: id,
      email: email,
    };

    postSendInvitation.mutate(data, {
      onSuccess: (response: { data: { message: { messageType: string; messageContent: SetStateAction<string>; }; }; }) => {
        if (response.data.message.messageType === "SUCCESS") {
          console.log('email', email);
          console.log('role', role);
          messageApi.success("Invitation envoyée avec succès !");
          reset({ email: '', role: '' }); 
          handleCloseAddMemberModal();
        } else {
          setErrorMessage(response.data.message.messageContent);
          setIsErrorDisplayed(true);
        }
      },
      onError: () => {
        setErrorMessage("Erreur lors de l'envoi de l'invitation");
        setIsErrorDisplayed(true);
      }
    });
  } else {
  if (!email) {
    hasError = true;
  }
  if (!role) {
    setRoleError("Le rôle est requis");
    hasError = true;
  }
  if (hasError) return;
}

};

const handleAnnulation = () =>{
  if (otpCode !== otpValue){
    setErrorAnnulation(`Le code saisi n'est pas valide. Essayez-le à nouveau!`);
  }
  else{
    if (selectedRowOption?.id) {
      postAnnulationInvitaionEservice.mutate({
        demandeJoindreId: selectedRowOption.id,
        typeRequest:'supprimer'
      });
    }
    handleCloseJoin();
  }
}

 const handleRoleSelect = (e: Event) => {
    const inputElement=e.target as HTMLInputElement
    acceptedRoleRef.current = inputElement.value;
  }

  const handleJoinEservice = () => {
    if (selectedRowOption?.id && acceptedRoleRef.current) {
      postAcceptJoinEservice.mutate({
        ownerlogin: selectedRowOption.ownerLogin,
        demandeJoindreId: selectedRowOption.id,
        EserviceId: id,
        acceptedRole: acceptedRoleRef.current,
      });
    } else {
      console.error('Id is not available');
    }
    setSelectedRowOption(null);
  }
  const steps = [
    {
      leftBtn: {
        label: 'Refuser',
        color: 'error',
        onClick: () => { setCurrentStep(steps[2]) },
      },
      rightBtn: {
        label: 'Approuver',
        color: 'success',
        onClick: () => { setCurrentStep(steps[1]) },
      },
      body: (<>
        <div className="flex gap-5 items-center">
          {
          selectedRowOption?.logoUser ?
          <img className={styles.imgProfile} src={selectedRowOption?.logoUser}></img> :
          <AvatarComponent fullname={selectedRowOption?.nameUser || ''} />

        }
        <div className="grid ">
            <span className={styles.nameUser}>{selectedRowOption?.nameUser}</span>
            <span className={styles.emailPerso}>{selectedRowOption?.emailPersonnel}</span>
          </div>
        </div>
        <div className={styles.endBlock}></div>
        <Select
          placeholder="Chosissez un élément"
          selectedValues={selectedRowOption?.role}
          onChange={handleRoleSelect}
          size='sm'
          label={'Rôle à approuver'}
          showSelectedIcon
          options={[{ label: 'Administrateur', value: 'Administrateur' }, { label: 'Citizen developer', value: 'Citizen developer' }]}
        />

        <span className={styles.textNote}><span className={styles.textNoteLabel}>Note : </span>{selectedRowOption?.note}</span>
      </>
      ),
    },
    {
      leftBtn: {
        label: 'Annuler',
        color: 'neutral',
        onClick: () => { setCurrentStep(steps[0]) },
      },
      rightBtn: {
        label: 'Valider',
        color: 'primary',
        onClick: handleJoinEservice,
      },
      body: (
        <div className={styles.textConfirmation}>
          Êtes-vous sûr de vouloir <span className={styles.textConfirmationAccept}>accepter</span> la demande ?
        </div>
      ),
    },
    {
      leftBtn: {
        label: 'Annuler',
        color: 'neutral',
        onClick: () => { setCurrentStep(steps[0]) },
      },
      rightBtn: {
        label: 'Valider',
        color: 'primary',
        onClick: () => handleRefuseJoinEservice(),
      },
      body: (
        <div className={styles.textConfirmation}>
          Êtes-vous sûr de vouloir <span className={styles.textConfirmationRefuse}>refuser</span> la demande ?
        </div>
      ),
    },
  ];
  console.log('selectedRowOption.nameUser', selectedRowOption?.nameUser);

  type ButtonColor = 'error' | 'success' | 'neutral' | 'primary';
  const [currentStep, setCurrentStep] = useState(steps[0]);


  const fBmember: FilterBarSortByProps = {
    items: [
      {
        data: [
          { label: 'Administrateur', value: 'ADMINISTRATEUR' },
          { label: 'Citizen developer', value: 'CITIZEN DEVELOPER' }
        ],
        label: 'Rôle',
        placeHolder: 'Rôle',
        selectedValue: roleFiltre,
        clearable: true,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }
          console.log(e);
          if (e == roleFiltre) return;
          console.log('hello',roleFiltre);
          setRequestMembersData([]);
          setRoleFiltre(e);
        }
      },
      ...(selectedButton === 'approuve'
        ? [
            {
              data: [
                { label: 'Actif', value: 'actif' },
                { label: 'Inactif', value: 'inactif' },
              ],
              label: 'Activation',
              placeHolder: 'Activation',
              selectedValue: actifFiltre,
              clearable: true,
              onChange: (e: string) => {
                if (page > 1) {
                  setPage(1);
                }
                if (e == actifFiltre) return;
                setRequestMembersData([]);
                setActifFiltre(e);
              }
            }
          ]
        : [])
    ]
  };

  const [allMembersParams, setAllMembersParams] =
    useState<EserviceMembersListRequest>({
      numPage: 1,
      pageSize: 10,
      key: '',
      idRequest: '',
      idEservice: id,
      typeRequest: typeRequest,
      statusRequest: statusRequest,
      statusRole: '',
      role: 'ADMINISTRATEUR,CITIZEN DEVELOPER',
      Fields: '',
    });

  const getEserviceMembersList = useGetEserviceMembersList(allMembersParams);
  useEffect(() => {
    if (actifFiltre) {
      setResetFilters(true)
    } else {
      setResetFilters(false)
    }
    setIsFetching(true);
    setAllMembersParams((prevData) => ({
      ...prevData,
      numPage: page,
      typeRequest: typeRequest,
      statusRequest: statusRequest,
      key: searchValue,
      role: 'ADMINISTRATEUR,CITIZEN DEVELOPER',
      statusRole:actifFiltre,
    }));

  }, [searchValue, page, typeRequest, statusRequest,actifFiltre]);

  useEffect(() => {
    if(roleFiltre)  {
      setResetFilters(true)
    setIsFetching(true);
    setAllMembersParams((prevData) => ({
      ...prevData,
      numPage: page,
      role:roleFiltre
    }));
    
  }else {
    setResetFilters(false)
    setIsFetching(true);
    setAllMembersParams((prevData) => ({
      ...prevData,
      numPage: page,
      role:'ADMINISTRATEUR,CITIZEN DEVELOPER',
    }));
  }
  }, [roleFiltre]);

  console.log(getEserviceMembersList, setAllMembersParams);
  const fBc: FilterBarContentProps = {
    label: 'Membres',
    searchLength: totalNumber,
    buttons: [
      {
        icon: StarSvg,
        label: 'Approuvés',
        isSelected: selectedButton === 'approuve',
        handleClick: () => {
          if (selectedButton === 'approuve') return;
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSelectedButton('approuve');
          setTypeRequest('');
          setStatusRequest('ACCEPT')
        }
      },
      {
        icon: SendSVG,
        label: 'Invitations',
        isSelected: selectedButton === 'invitations',
        handleClick: () => {
          if (selectedButton === 'invitations') return;
          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSelectedButton('invitations');
          setTypeRequest('INVITATION');
          setStatusRequest('Brouillon');
        }
      },
      {
        icon: TimeSvg,
        label: 'Demandes',
        isSelected: selectedButton === 'demandes',
        handleClick: () => {
          if (selectedButton === 'demandes') return;

          if (page > 1) {
            setPage(1);
          }
          setRequestMembersData([]);
          setSelectedButton('demandes');
          setTypeRequest('DEMANDE');
          setStatusRequest('Brouillon');

        }
      }
    ],
    buttonAddArgs:{
      label: 'Ajouter membre',
      args: { onClick: handleAddMemberClick },
      color: 'primary',
      size: 'sm',
      styling: 'outline',
      leftIcon:<MaterialIcon label="add"/>
    }
  }
  const handleCloseJoin = () => {
    setOtpValue('');
    setSelectedRowOption(null);
    setErrorAnnulation('');
    setOtpCode(generateOtp(6))
  }


  const handleRefuseJoinEservice = () => {
    if (selectedRowOption?.id) {
      postRefuseJoinEservice.mutate({
        ownerlogin: selectedRowOption.ownerLogin,
        demandeJoindreId: selectedRowOption.id,
        EserviceId: id,
      });
    } else {
      console.error('Id is not available');
    }
    setSelectedRowOption(null);
  }

  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters,
      reset: resetFilters,
      resetClick: () => {
       setActifFiltre('');
       setRoleFiltre('');
        setResetFilters(false);
      }
    },

    isGridDisplayMode: false,
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setRequestMembersData([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setRequestMembersData([]);
      setSearchValue('');
    },
    onSearch: (e) => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setRequestMembersData([]);
      setSearchValue(e);
    },
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },


  };

  useEffect(() => {
    console.log('selectedRowOprion', selectedRowOption)
    if (selectedRowOption) {
      setCurrentStep(steps[0]);
    }

  }, [selectedRowOption]);

  useEffect(() => {
    if (getEserviceMembersList.isFetching) {
      setIsFetching(true);
    }
    if (getEserviceMembersList.isSuccess) {
      const membersListData = getEserviceMembersList.data;
      const totalItems = getEserviceMembersList.data.body.pagination.totalLength;

      if (membersListData) {
        if (totalItems !== undefined) {
          setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
          setRequestMembersData(membersListData.body.data);

        }
        setIsFetching(false);
      }
      return;
    }

  }, [getEserviceMembersList.fetchStatus])

  useEffect(() => {
    if (postAcceptJoinEservice.isPending) {
      messageApi.loading({
        content: 'Loading...',
        duration: 0
      });

    }
    if (postAcceptJoinEservice.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'Votre demande a été approuvé avec succès',
        duration: 2.5
      });
    }
    if (postAcceptJoinEservice.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postAcceptJoinEservice.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postAcceptJoinEservice.status]);

  useEffect(() => {
    if (postAnnulationInvitaionEservice.isPending) {
      messageApi.loading({
        content: 'Loading...',
        duration: 0
      });

    }
    if (postAnnulationInvitaionEservice.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'Invitation annulée avec succès !',
        duration: 2.5
      });
    }
    if (postAnnulationInvitaionEservice.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postAnnulationInvitaionEservice.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postAnnulationInvitaionEservice.status]);

  useEffect(() => {

    if (postRefuseJoinEservice.isPending) {
      messageApi.loading({
        content: 'Loading...',
        duration: 0
      });

    }
    if (postRefuseJoinEservice.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'La demande a été refusé avec succès',
        duration: 2.5
      });
    }
    if (postRefuseJoinEservice.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postRefuseJoinEservice.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postRefuseJoinEservice.status]);
  return (
    <div className="flex flex-col gap-7 p-5 h-[100%]">
      {contextHolder}
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarSortBy={fBmember}
      />
      {requestMembersData.length < 1 ? <EmptyData
        icon={emptyIcon}
        title={"Aucun pour l'instant"}
        description={"La liste sera affichée ici"}
      /> : <>{selectedButton == 'approuve' ? (
        <> {showTable &&
          <Table
            isFitching={isFetching}
            type="memberApprouve"
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalNumber}
            onSelectDropDownForMembers={setSelectedRowOption}
            headerItems={{
              items: ['Membre', '', 'Login', 'Rôle', 'Activation']
            }}
            rows={requestMembersData}
          />}

          {showGrid &&             <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-auto overflow-auto">
             {requestMembersData.map(r => (
            <UserCard
              key={r.idRequest}
              firstName={r.nameUser}
              email={r.loginUser}
              profilePicture={r.logoUser}
              color=""
              type="memberApprouve"
              role={{ role: r.roleUserApproved, status: r.roleStatusUser }}
              listBasedRole={false}
            />
            
          ))}
          </div>
          }
        </>
      )
        : <>{showTable &&
          <Table
            isFitching={getRequestJoinEserviceList.isFetching}
            type ={ selectedButton == 'invitations' ? 'invitationNonApprouve' : 'memberNonApprouve' }
            setCurrentPage={(page) => setPage(page)}
            currentPage={page}
            totalItems={totalNumber}
            onSelectDropDownForMembers={setSelectedRowOption}
            headerItems={{
              items: ['Membre', '', 'Login', 'Rôle en attente']
            }}
            rows={requestMembersData}
          />
        }
          {showGrid && <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 h-auto overflow-auto">
            {requestMembersData.map(r => (
              <UserCard
                key={r.idRequest}
                firstName={r.nameUser || 'N/A'}
                email={r.loginUser || r.emailPersonnel}
                profilePicture={r.logoUser}
                color=""
                role={{ role: r.roleUserDemanded, status: r.roleStatusUser, roleType: r.typeRequest }}
                type={ selectedButton == 'invitations' ? 'invitationNonApprouve' : 'memberNonApprouve' }
                onSelectDropDownForMembers={setSelectedRowOption}
                member={r}
              />
            ))}
          </div>
          }
        </>
      }</>}


      {selectedRowOption && selectedRowOption.selectedValue === 'traiter' &&
        <Modal onClose={handleCloseJoin}
          title={"Traitement demande"}
          leftBtnArgs={{
            label: currentStep.leftBtn.label,
            args: { onClick: currentStep.leftBtn.onClick },
            color: currentStep.leftBtn.color as ButtonColor,
            size: 'xs',
            styling: 'outline'
          }}
          rightBtnArgs={{
            label: currentStep.rightBtn.label,
            args: { onClick: currentStep.rightBtn.onClick },
            color: currentStep.rightBtn.color as ButtonColor,
            size: 'xs',
            styling: 'solid'
          }}
          icon={""}
        >
          <div className="flex flex-col p-6 gap-5">
            {currentStep.body}
          </div>
        </Modal>

      }
         {selectedRowOption  &&
          <SimpleModal
        type="error"
        cancelLabel="Annuler"
        validateLabel="Supprimer"
        isOpen={(selectedRowOption.selectedValue === 'annuler')}
        onValidate={handleAnnulation}
        onCancel={handleCloseJoin} 
        afterClose={handleCloseJoin}
        title={"Annulation invitation"}
        disabled ={(otpValue?.length != 6)}
       >
          <div className="flex flex-col px-5 gap-5">
          <OtpForm code={otpCode} handleChange={setOtpValue} ></OtpForm>
          {errorAnnulation && (                
        <NotificationCard
          type={NotificationType.ERROR}
           >
          {errorAnnulation}
        </NotificationCard>
      )}
          </div>
          </SimpleModal>
      }
    
{showAddMemberModal && (
  
         <Modal
         
         onClose={handleCloseAddMemberModal}
         title="Ajout membre"


         leftBtnArgs={{
           label: "Annuler",
           args: { onClick: handleCloseAddMemberModal },
           color: "neutral",
           size: "xs",
           styling: "outline",
         }}

         rightBtnArgs={{
           label: "Ajouter",
           args: { onClick: handleSubmit(handleAddMember) },
           color: "primary",
           size: "xs",
           styling: "solid",
         }}
         icon={""}
       >
         <div className="flex flex-col p-6 gap-5">
        {errorMessage && (                
        <NotificationCard
          type={NotificationType.ERROR}
          className={errorMessageStyles} >
          {errorMessage}
        </NotificationCard>
      )}
          <InputText
              size="sm"
              label="Email"
              control={control}
              name="email"
              placeholder={tO('invite_Member_Email_placeholder')}
              error={errors.email?.message}
              withError
            />
            
            <ControlledSelect
              control={control}
              name="role"
              label="Rôle"
              placeholder="Sélectionnez un rôle"
              withError
              error={roleError || errors.role?.message} 
              options={[
                { label: 'Administrateur', value: 'ADMINISTRATEUR' },
                { label: 'Citizen developer', value: 'CITIZEN DEVELOPER' }
              ]}
            />

         </div>
       </Modal>
      )}
    </div>);
}
