import classNames from "classnames";
import {useEffect,useState} from "react";
import {useGetCheckWindowOrganizationStatus,} from "../../../../../api";
import {useParams} from "react-router-dom";
import {message, Spin} from 'antd'
import {OrganizationJoiningInterface} from "./interfaces/OrganizationJoiningInterface";
import {MessageInstance} from "antd/es/message/interface";
import {OrganizationCreationInterface} from "./interfaces/OrganizationCreationInterface";
import {OrganizationInProgressInterface} from "./interfaces/OrganizationInProgressInterface";
import {OrganizationParamsInterface} from "./interfaces/OrganizationParamsInterface";



export enum OrganizationInterfaces{
    NOT_ASSOCIATED="NOT_ASSOCIATED",
    CREATION="CREATION",
    ASSOCIATE_IN_PROGRESS="ASSOCIATE_IN_PROGRESS",
    ASSOCIATED="ASSOCIATED",
}
export interface OrganizationIdentityTabsProps {
    onSwitch?: (to: OrganizationInterfaces) => void;
    className?: string;
    messageApi: MessageInstance
}

export const OrganizationIdentityTab=()=>{
   const [currentInterface,setCurrentInterface]=useState<OrganizationInterfaces>();
    const [messageApi, contextHolder] = message.useMessage();
    const {guichetId}=useParams();
      const interfacesStyles = (_interface: OrganizationInterfaces) =>
        classNames({
            "transition-opacity duration-400": true,
            ['show']: currentInterface === _interface,
            ['notDisplayV2']: currentInterface !== _interface
        });
    const getCheckWindowOrganizationStatus=useGetCheckWindowOrganizationStatus({guichetId});
    const handleInterfaceSwitch=(_interface:OrganizationInterfaces)=>setCurrentInterface(_interface)
    const renderInterfaces=()=>{
        switch (currentInterface){
            case OrganizationInterfaces.ASSOCIATED:return  <OrganizationParamsInterface className={interfacesStyles(OrganizationInterfaces.ASSOCIATED)} messageApi={messageApi}/>
            case OrganizationInterfaces.ASSOCIATE_IN_PROGRESS:return  <OrganizationInProgressInterface className={interfacesStyles(OrganizationInterfaces.ASSOCIATE_IN_PROGRESS)} messageApi={messageApi}/>
            default :
                return <>
                  <OrganizationJoiningInterface onSwitch={handleInterfaceSwitch} messageApi={messageApi} className={interfacesStyles(OrganizationInterfaces.NOT_ASSOCIATED)}/>
                  <OrganizationCreationInterface onSwitch={handleInterfaceSwitch} messageApi={messageApi} className={interfacesStyles(OrganizationInterfaces.CREATION)}/>
                       </>
        }
    }



    useEffect(() => {
        console.log("ytr",getCheckWindowOrganizationStatus.customHandler.isSuccess ,!getCheckWindowOrganizationStatus.isFetching)
        if (getCheckWindowOrganizationStatus.customHandler.isSuccess && !getCheckWindowOrganizationStatus.isFetching)  {
            switch(getCheckWindowOrganizationStatus.customHandler.state){
            case"NOT_ASSOCIATED":setCurrentInterface(OrganizationInterfaces.NOT_ASSOCIATED);break;
            case"ASSOCIATE_IN_PROGRESS":setCurrentInterface(OrganizationInterfaces.ASSOCIATE_IN_PROGRESS);break;
            case"ASSOCIATED":setCurrentInterface(OrganizationInterfaces.ASSOCIATED);break;
            }
    }
    }, [getCheckWindowOrganizationStatus.customHandler.isSuccess,getCheckWindowOrganizationStatus.isFetching]);



      return (
        <div>
            {contextHolder}
            {
                getCheckWindowOrganizationStatus.isFetching &&
                <div className="w-full flex justify-center min-h-[30vh] items-center">
                <Spin size="large"/>
                </div>
            }
            {getCheckWindowOrganizationStatus.customHandler.isSuccess &&  !getCheckWindowOrganizationStatus.isFetching && renderInterfaces()}
        </div>
      )
}









