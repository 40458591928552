import {RefObject, useCallback, useEffect, useRef, useState} from "react";


export const useHandleComponentVisibility = (notifier?:()=>void) => {
    const [isOpen, setOpenStatus] = useState<boolean>(false);
    const ref: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (isOpen && ref.current && !ref.current.contains(event.target as Node)) {
            setOpenStatus(false);
           if (notifier) notifier();
        }
    },[isOpen])

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [isOpen]);

    return { ref, isOpen, setOpenStatus };
};