import { FC } from 'react';
import styles from './table-items.module.css';
import { MaterialIcon } from '../../../../../shared/components/icons/MaterialIcon.tsx';
import { Menu } from '../../../../../shared/components/index.tsx';
import { Administrator } from '../cards/CardComponents/CardAdministrators.tsx';
import { RoleTag } from "../user-card/UserCard.tsx"
interface RowTableAdministrateursProps {
  item: Administrator;
  onSelectDropDown?: (selection: { id: string; selectedValue: string }) => void;
  dropDownData?: { label: string; value: string; icon: JSX.Element }[];
  icon?: string;
}

export const RowTableAdministrateurs: FC<RowTableAdministrateursProps> = ({ item, onSelectDropDown, dropDownData }) => {
  const handleSelect = (value: string) => {
    if (onSelectDropDown) {
      onSelectDropDown({ id: item.id, selectedValue: value });
    }
  };

  const renderMenu = () => {
    if (dropDownData) {
      return (
        <Menu
          className={styles.moreIcon}
          icon={<MaterialIcon label="more_vert" className={styles.menuIcon} />}
          onChange={(selectedValue: string[]) => handleSelect(selectedValue[0])}
          list={dropDownData}
        />
      );
    }
    return null;
  };
  const getAvatarName = (fullName: string, email: string) => {
    const nameParts = fullName.split(' ');
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();
    if (firstNameInitial == '' && lastNameInitial == '') {
      return email != '' ? email.charAt(0).toUpperCase() : '';
    } else {
      return firstNameInitial + lastNameInitial;
    }
  }

  const AdminProfile: FC<{ item: Administrator }> = ({ item }) => {
    return (
      <div className={styles.profileInfo}>
        <div className="rounded-full w-full h-full flex items-center justify-center">
          {item.profilePicture ? (
            <img
              src={item.profilePicture}
              alt="Profile"
              className="aspect-square size-8 rounded-full"
            />
          ) : (
            <div className={styles.avatarProfile}>
              <span className={styles.avatarTitle}>
                {getAvatarName(item.firstName, item.email)}
              </span>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-start">
          <span className={styles.profileCardTitle}>{`${item.firstName}`}</span>
          <span className={styles.profileCardDescription}>{item.email}</span>
        </div>
      </div>
    );
  };


  return (
    <tr key={item.id}>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <AdminProfile item={item} />
        </div>
      </td>
      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>{item.email}</div>
      </td>

      <td className={styles.aCaseContainer}>
        <div className={styles.cardContainer}>
          <RoleTag role={item.roleDetails?.role} roleType={item?.roleDetails?.roleType} />
        </div>
      </td>
      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
          <span className={styles.statutContainerNV}>
            {item.roleDetails?.status}
          </span>
          {item.roleDetails?.role !== "Propriétaire" && <div className={styles.headerIcons}>{renderMenu()}</div>}
        </div>
      </td>
    </tr>
  );
};
