import { Input } from "kls-ui";
import { AdvancedUploader, InputText, NotificationCard, SimpleModal } from "../../../../../../shared/components";
import { Spin } from "antd";
import { ChangeEvent, FC, useState } from "react";
import { useCheckForDataConsistency, useCheckForGraphs, useCheckForHeaders, useCheckForMergedCells } from "../../../../api";
import { MAX_CARACTER_NAME } from "../../../../utils/constants/helpers.constants";
import { NotificationType } from "../../../../../../shared/utils/enums";
import { cleanAndFormatNameResource } from "../../../../utils/helpers/validators.helper";
import { MessageInstance } from "antd/es/message/interface";
import { useParams } from "react-router-dom";

interface EserviceExcelCreationModalProps {
    guichetName:string;
    messageApi: MessageInstance;
    isOpen:boolean;
    onCancel:()=>void;
}
export const EserviceExcelCreationModal :FC<EserviceExcelCreationModalProps> = (props)=>{
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileWarning, setFileWarning] = useState<{ isError: boolean; message: string | null }>({
        isError: false,
        message: null,
      });
      const [isLoading, setIsLoading] = useState<boolean>(false);
      const { mutateAsync: checkGraphs } = useCheckForGraphs();
      const { mutateAsync: checkMergedCells } = useCheckForMergedCells();
      const { mutateAsync: checkHeaders } = useCheckForHeaders();
      const { mutateAsync: checkDataConsistency } = useCheckForDataConsistency();
      const [errorMessageCrea, setErrorMessageCrea] = useState('');
      const [nomService, setNomService] = useState('');
      const { guichetId } = useParams<{ guichetId: string }>();

      const handleDelete =(e: React.MouseEvent<HTMLSpanElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        setSelectedFile(null);
      }

    const handleExcelCreation = () => {
        if (!selectedFile) {
            setFileWarning({ isError: true, message: 'Veuillez importer un fichier Excel' });
        }
    
        if (!nomService || nomService.length < 2) {
            setErrorMessageCrea("Ce champ est requis");
            return;
        }
    
        if (!fileWarning.isError && !errorMessageCrea && selectedFile) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64File = reader.result as string;
            sessionStorage.setItem('selectedFile', base64File);
            const location = {
              pathname: `/studio/${guichetId}/editExcel/${nomService}/${selectedFile.name}`,
              state: { eFile: selectedFile }
            };
            window.open(location.pathname, '_blank');
          };
    
          reader.readAsDataURL(selectedFile);
        }
      };
      const handleOpenFile = () => {
        const fileUrl = URL.createObjectURL(selectedFile!);
        if (fileUrl) {
          window.open(fileUrl, "_blank");
        }
      };

      const handleCancel = ()=>{
        setSelectedFile(null);
        setNomService('');
        setErrorMessageCrea('');
        setFileWarning({isError:false,message:''})
        props.onCancel();
      }

    const uploadFile = async (file:File) => {
        setIsLoading(true)
        setFileWarning({isError: false,message: null,});
        if (file.name.endsWith('.csv') || file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          if(file.name.endsWith('.xlsx')|| file.name.endsWith('.xls')){
            const [mergedCellsFound, graphsFound, headerFound] = await Promise.all([
              checkMergedCells(file),
              checkGraphs(file),
              checkHeaders(file),
            ]);
        
            const isValidFile = !(mergedCellsFound.found || graphsFound.found || !headerFound);
            if (isValidFile) {
                const dt_check = await checkDataConsistency(file);  
                console.log("DATA TYPE CHECK: ",dt_check);                             
            }
            else setFileWarning({isError:true, message:"Veuillez importer un fichier Excel valide (sans graphiques, sans cellules fusionnées...)"});
          }
          setSelectedFile(file);
          setIsLoading(false);
          return;
        } 
        setSelectedFile(null);
        setFileWarning({isError: true,message:'Veuillez importez un fichier Excel'});
        setIsLoading(false);
      }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setErrorMessageCrea('');
        const value = e.target.value;
        if (value?.length <= MAX_CARACTER_NAME) {
          setNomService(value);
        }
      }

    return (
        <SimpleModal
        type="primary"
        cancelLabel="Annuler"
        validateLabel="Ajouter"
        isOpen={props.isOpen}
        onValidate={handleExcelCreation}
        onCancel={handleCancel} 
        afterClose={handleCancel}
        title={"Application à partir d'Excel"}
       >
        <div className="flex flex-col gap-3 px-2 min-w-[450px]">
          { fileWarning.isError &&<NotificationCard
              type={NotificationType.ERROR}>
              {fileWarning.message}
            </NotificationCard>
          }
          <Input
            errorMessage={errorMessageCrea}
            size="sm"
            label="Nom de e-service"
            helpText={`ID :${cleanAndFormatNameResource(nomService)}`}
            args={{
              placeholder: "Entrez le nom de votre e-service",
              value: nomService,
              onChange: handleNameChange
            }}
            charCounter={MAX_CARACTER_NAME}
          />
          <InputText
            label="Guichet"
            size="sm"
            value={props.guichetName}
            disabled
          />
          {
            !selectedFile
              ? <Spin spinning={isLoading} size="large">
                  <AdvancedUploader title="Glissez et déposez un fichier de feuille ici" 
                    description="Formats supportés : xls, csv, xlsx" 
                    accept="application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                    onUploadFiles={(files)=>{uploadFile(files[0])}} 
                  />
                </Spin>
              :<div className="flex justify-between items-center rounded border border-slate-200 dark:border-slate-500 px-2.5 py-2">
                <div className="flex gap-3">
                  <span className="moreicon icon text-slate-500 dark:text-slate-300">
                    description
                  </span>
                  <span className="font-medium text-[13px] font-inter leading-[18px] text-slate-800 dark:text-white">
                    {selectedFile.name}
                  </span>
                  <span className="font-normal text-[12px] font-inter leading-[18px] text-slate-500 dark:text-slate-300">
                    {(selectedFile.size / 1024).toFixed(2)} KB
                  </span>
                </div>
                <div className="flex gap-3">
                  <span
                    className="moreicon icon text-blue-600 cursor-pointer"
                    onClick={() => handleOpenFile()}
                  >
                    download
                  </span>
                  <span
                    className="moreicon icon text-red-500 cursor-pointer"
                    onClick={(e)=>handleDelete(e)}
                  >
                    delete
                  </span>
                </div>
              </div>
            }
        </div>
        </SimpleModal>
      );
}