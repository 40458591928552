import {useLocation, useParams} from "react-router-dom";
import {USER_ROUTE_LOCATION_PATHNAME, WINDOW_ROUTE_LOCATION_PATHNAME} from "../utils/constants";
import { GUICHETS_ROUTE_LOCATION_PATHNAME } from "../utils/constants/Routes.constants";

interface RouteParams extends Record<string, string | undefined> {
    windowId?: string;
    fileUrl?: string;
}
export const useHandleDomLocation=()=>{
    const location=useLocation();
    const profilePathName=location.pathname.split("/")[1];
    const isGuichetPage=(location.pathname.split("/")[2] === GUICHETS_ROUTE_LOCATION_PATHNAME )&&location.pathname.split("/").length == 3;
    const isWindowProfile=profilePathName===WINDOW_ROUTE_LOCATION_PATHNAME;
    const isUserProfile=profilePathName===USER_ROUTE_LOCATION_PATHNAME;
    return {isGuichetPage,isUserProfile,profilePathName,isWindowProfile,...useParams<RouteParams>()};
}