import { ApiJaxRsResponse, DynamicListJaxRsRequest } from "../../../../shared/types";

export const ESERVICE_LIST_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/appmanager/allEservices",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
   }

export const ESERVICE_CREATION_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/localappmanager/createEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   }   

   export const REQUEST_JOIN_ESERVICE_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/usermanager/sendRequestToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   }   

   export const ESERVICE_LIST_REQUEST_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/usermanager/allRequestToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   } 
   
   export const ACCEPT_REQUEST_JOIN_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/usermanager/acceptRequestToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   } 
   export const UPDATE_ESERVICE_INFO:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/localappmanager/updateEServiceInfo",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   } 

   export const FETCH_ESERVICE_DETAILS:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/localappmanager/getDetailsApplication",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   } 

   export const REFUSE_REQUEST_JOIN_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/usermanager/refuseRequestToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   } 

   export const CATEGORIES_LIST_API_PATH:DynamicListJaxRsRequest ={
    path:"/karazal/ow-kls-extrat/appmanager/dynamicList",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
    resource:"resources/compte/service/categories"
   }

   export const ADD_FAVORITE_ESERVICE_API_PATH: ApiJaxRsResponse = {
    path: '/karazal/ow-kls-extrat/appmanager/addFavoriteEserviceUser',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
  };

 
  export const ESERVICE_MEMBERS_LIST_API_PATH: ApiJaxRsResponse = {
    path: '/karazal/ow-kls-extrat/usermanager/allRequest',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
  };

  export const SEND_INVITATION_ESERVICE_API_PATH = {
    path: "/karazal/ow-kls-extrat/usermanager/sendInvitationToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
  };

  export const INVITE_ADMINISTRATOR_PAGE_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/usermanager/sendRequestToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   } 
  export const ANNULATION_INVITATION_ESERVICE_API_PATH = {
    path: "/karazal/ow-kls-extrat/usermanager/annulerToJoinEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
  };
  
  export const ADD_ESERVICE_TO_ESHOP_API_PATH ={
    path:'/karazal/ow-kls-extrat/appmanager/addRaccourcisToGuichet',
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6" 
   }
