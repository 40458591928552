import {ChangeEvent, FC, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useGetOrganizationByWindow, useGetWindowOrganizationAssociationRequests} from "../../../../../../api";
import {getWindowRequestsColumns} from "../../../table-columns/window.table-columns";
import {ImageUploader} from "../../../../../../components/ui";
import {getKarazFileUrl} from "../../../../../../../../shared/utils/helpers";
import classNames from "classnames";
import {
    InputText as NativeInputText,
    TextArea as NativeTextArea,
    InputPhone as NativeInputPhone,
    GenericTable,
    InputSearch,
    Select as NativeSelect
} from "../../../../../../../../shared/components";
import {OrganizationIdentityTabsProps} from "../OrganizationIdentityTab";

export const OrganizationParamsInterface: FC<OrganizationIdentityTabsProps> = (props) => {
    const {className = ""} = props;
    const {guichetId} = useParams();
    const getOrganizationByWindow=useGetOrganizationByWindow({guichetId});
    const [pagination,setPagination]=useState<{numPage:number,pageSize:number}>({numPage:1,pageSize:2});
    const [filterRequest,setFilterRequest]=useState({organismeId:guichetId ?? "",organismName:"",statusRequest:""});
    const getWindowOrganizationRequests=useGetWindowOrganizationAssociationRequests({...filterRequest,...pagination});
    const rootStyles=classNames({
        [className]:true,
        "flex flex-col gap-7":true
    })
    const handleSearchChange=(e:ChangeEvent<HTMLInputElement>)=>{
        setFilterRequest({...filterRequest,organismName:e.target.value});
    }
    const handleSelectStatusChange=(e:Event)=>{
        const inputElement=e.target as HTMLInputElement
        setFilterRequest({...filterRequest,statusRequest:inputElement.value});
    }
    const sectionTitleStyle = classNames("text-blue-700 text-sm font-semibold")
    const COLUMNS=useMemo(getWindowRequestsColumns,[])
    const STATUS_REQUEST_OPTIONS=[{label:"En attente approbation",value:"Brouillon"},{label:"Approuvée",value:"Approuvée"},{label:"Refusée",value:"Refusée"}];
    return (
        <div className={rootStyles}>
            <ImageUploader readOnly defaultImageUrl={getKarazFileUrl(getOrganizationByWindow.customHandler.data?.logoGedId ?? "")}/>
            <div className="flex flex-col gap-5 justify-center">
                <span className={sectionTitleStyle}>Informations générales</span>
                <div className="grid grid-cols-3 gap-4">
                    <NativeInputText size="lg" label="Nom" value={getOrganizationByWindow.customHandler.data?.name} readOnly/>
                    <NativeInputText  size="lg" label="Acronyme"  value={getOrganizationByWindow.customHandler.data?.acronyme}  readOnly />
                    <NativeInputText  size="lg" label="Propriétaire"  value={getOrganizationByWindow.customHandler.data?.Proprietaire}  readOnly />
                </div>

                <NativeTextArea label="Description"  size="lg" value={getOrganizationByWindow.customHandler.data?.description} readOnly />
            </div>

            <div className="flex flex-col gap-4 justify-center">
                <span className={sectionTitleStyle}>Coordonnées</span>
                <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-4">
                    <NativeInputText size="lg" label="Email" value={getOrganizationByWindow.customHandler.data?.email} readOnly/>
                    <NativeInputPhone label="Téléphone" prefixPlaceholder="indicatif" size="lg" value={{prefix:getOrganizationByWindow.customHandler.data?.indicatif,suffix:getOrganizationByWindow.customHandler.data?.phoneNumber}} disabled  />
                    <NativeInputText size="lg" label="N° identifiant" value={getOrganizationByWindow.customHandler.data?.identifiant} readOnly/>
                    <NativeInputText size="lg" label="Site web" value={getOrganizationByWindow.customHandler.data?.site} readOnly/>
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <span className={sectionTitleStyle}>Guichets associés</span>
                <div className="grid grid-cols-[70%_25%] gap-3 ">
                    <InputSearch placeholder="Rechercher" size="lg"  onChange={handleSearchChange} label="Rechercher" hideLabel />
                    <NativeSelect options={STATUS_REQUEST_OPTIONS} label="Statut" placeholder="Statut" size="lg" onChange={handleSelectStatusChange}/>
                </div>


            </div>
            <GenericTable data={getWindowOrganizationRequests.data?.body?.data || []} columns={COLUMNS} pagination={pagination} rowCount={getWindowOrganizationRequests.customHandler.data?.pagination.totalLength} handlePaginationChange={setPagination} isLoading={getWindowOrganizationRequests.isFetching}/>
        </div>
    )
}
