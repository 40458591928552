import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.markercluster';
import { FC, useEffect } from 'react';
import '../map.css';
import { Guichet } from '../../../../modules/portal/components/ui/cards/base-cards/CardGuichet';

interface MarkerClusterContainerProps {
  markersData: Guichet[];
  openAction?: (selection: { id: string; selectedValue: string }) => void;
}

export const MarkerClusterContainer: FC<MarkerClusterContainerProps> = ({
  markersData,
  openAction
}) => {
  const createClusterCustomIcon = (cluster: L.MarkerCluster) => {
    const count = cluster.getChildCount();
    let size = 'large';

    if (count < 10) {
      size = 'small';
    } else if (count < 20) {
      size = 'medium';
    }

    return L.divIcon({
      html: `<div class="marker-cluster marker-cluster-${size}"><span>${count}</span></div>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(40, 40, true)
    });
  };

  const map = useMap();

  useEffect(() => {
    const markerClusterGroup = L.markerClusterGroup({
      iconCreateFunction: createClusterCustomIcon
    });
    const customIcon = L.icon({
      iconUrl: '/pictures/marker-logo.png',
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -8]
    });

    markersData.forEach((markerData: Guichet) => {
      if (!markerData.lat || !markerData.lng) return;

      const template =
        `
        <div class="flex flex-col font-inter text-xs gap-2 w-full">
          <div class="flex justify-start items-center gap-2 w-full font-bold">
            <img class="w-7 h-7 rounded-full" src="${markerData.imageUrl}" alt="Marker Image" />
            ${markerData.name}
          </div>` +
        (markerData.organisationparent !== ''
          ? `<div class="flex justify-start gap-1 items-center">
            Propriété de <span class="font-bold">${markerData.organisationparent}</span>
          </div>`
          : '') +
        `<div class='flex justify-between items-center'>
            <span>0 dossiers</span>
            <span class="flex gap-1 font-medium access-btn" data-id="${markerData.id}" style="color:var(--blue-500); cursor:pointer;">
              <span class="material-icon">arrow_outward</span>Accéder
            </span>
          </div>
        </div>
      `;

      const marker = L.marker([markerData.lat, markerData.lng], {
        icon: customIcon,
        title: markerData.name
      });

      marker.bindPopup(template);

      marker.on('popupopen', () => {
        // Get the popup container and add the click event to the specific element
        const popupContent = marker.getPopup()?.getElement();
        const accessButton = popupContent?.querySelector('.access-btn');

        const handleClick = () => {
          if (openAction) {
            openAction({ id: markerData.id, selectedValue: 'open' });
          }
        };

        if (accessButton) {
          accessButton.addEventListener('click', handleClick);
        }

        // Clean up the event listener when the popup closes
        marker.on('popupclose', () => {
          if (accessButton) {
            accessButton.removeEventListener('click', handleClick);
          }
        });
      });

      markerClusterGroup.addLayer(marker);
    });

    map.addLayer(markerClusterGroup);

    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [map, markersData, openAction]);

  return null;
};
