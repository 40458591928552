import {FC} from "react";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    getPaginationRowModel,
    ColumnDef, Header,
} from '@tanstack/react-table';
import {EmptyData} from "../empty-data";
import {Spin} from "antd";
import classNames from "classnames";
import {Pagination} from "../pagination/Pagination";

interface GenericTableProps{
data:object[],
columns:ColumnDef<object, object>[],
isLoading?:boolean,
emptyData?:{title:string,description:string},
pagination : {numPage:number,pageSize:number},
rowCount?:number
handlePaginationChange:(pagination:{numPage:number,pageSize:number})=>void;
}
export const GenericTable:FC<GenericTableProps>=(props)=>{
    const {isLoading=false,emptyData={title:"Aucune donnée trouvée",description:""},rowCount=0}=props
    const table = useReactTable({
        data:props.data,
        columns:props.columns,
        initialState:{pagination:{pageIndex:props.pagination.numPage,pageSize:props.pagination.pageSize}},
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });
     const headerStyles=(header:Header<object, unknown>)=>classNames({
         ["cursor-pointer select-none flex min-w-[36px]"]:header.column.getCanSort(),

     })
    const bodyStyles=(isLast:boolean)=>classNames({
        ["px-4 pt-[14px] pb-[18px] text-slate-800 dark:text-white"]:true,
        ["border-b border-slate-200 dark:border-slate-700"]:!isLast
    })
    const renderTableBody=()=>{
        if (isLoading) return (<td colSpan={table.getAllColumns().length}>
            <div className="flex min-h-[20vh] justify-center items-center w-full">
                <Spin size="large"/>
            </div>
        </td>)
        if(table.getRowCount()==0) return(
            <td className="bg-white dark:bg-slate-900 " colSpan={table.getAllColumns().length}>
                <EmptyData  searchIcon title={emptyData?.title} description={emptyData?.description}/>
            </td>
           )
       return (
            table.getCoreRowModel().rows.map((row) => (
                <tr key={row.id} >
                    {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}  className={bodyStyles(table.getPaginationRowModel().rows.length-1==row.index)}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            )))
    }
    const handlePaginationChange=(page:number,options:{ pageSize: number; [key: string]:unknown;})=>{
         props.handlePaginationChange({...props.pagination,numPage:page,pageSize:options.pageSize})
    }
    return (
        <div className="flex flex-col justify-center gap-7">
        <table className="border border-solid border-slate-200 dark:border-slate-700 rounded-md w-full overflow-hidden ">
            <thead className="text-slate-500 border border-solid bg-slate-50  dark:bg-slate-950 text-xs">
            {table.getHeaderGroups().map((headerGroup) => (
                <tr
                    key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <th
                            key={header.id}
                            className="px-4 pr-2 py-4 border-b border-slate-200 dark:border-slate-700 font-medium"
                        >
                            {header.isPlaceholder ? null : (
                                <div
                                    {...{
                                        className: headerStyles(header),
                                        onClick: header.column.getToggleSortingHandler(),
                                    }}
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                    {{
                                        asc: <span className="pl-2">↑</span>,
                                        desc: <span className="pl-2">↓</span>,
                                    }[header.column.getIsSorted() as string] ?? null}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {renderTableBody()}
            </tbody>
        </table>
            <Pagination total={rowCount} onChange={handlePaginationChange} pageSize={props.pagination.pageSize} maxPage={5}/>
    </div>)
}