import { InputText } from '../../input/input-text/InputText';
import { List } from '../../list/List';
import { useEvent, useHandleComponentVisibility } from '../../../hooks';
import { SelectProps } from '../../../types';
import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useState
} from 'react';
import classNames from 'classnames';
import { TagElement } from '../../tags/tag-element/TagElement';

const ForwardedSelect: ForwardRefRenderFunction<
  HTMLInputElement,
  SelectProps
> = (props, ref) => {
  const {
    size = 'sm',
    withError = false,
    multiple = false,
    showSelectedIcon,
    vertical=false
  } = props;
  const handleEvent = (e: Event) => {
    if (props.onChange) props.onChange(e);
  };
  const [inputRef, emitEvent] = useEvent<HTMLInputElement>(
    'input-select',
    handleEvent
  );
  const {
    isOpen,
    setOpenStatus,
    ref: visibilityRef
  } = useHandleComponentVisibility();
  const [inputValue, setInputValue] = useState<string>();
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const listSize = ['sm', 'md'].includes(size) ? 'sm' : 'md';
  const [BeforeTextIcon, setBeforeTextIcon] = useState<ReactNode>();
  const getElementFromValue = (value: string) =>
    props.options.find((item) => item.value === value);
  const getSelectedElements = () =>
    props.options.filter((option) => selectedLabels.includes(option.label));
  const getSelectedValues = () =>
    getSelectedElements().map((option) => option.value);
  const open = () => {
    if (props.disabled) return;
    setOpenStatus((prevData)=>!prevData);
  };
  const expandIconStyle = classNames({
    ['material-flex-icon text-base text-slate-500 cursor-pointer transition-all duration-300']:
      true,
    ['rotate-180']: isOpen
  });

  const ExpandIcon = <i className={expandIconStyle}>expand_more</i>;

  const handleListChange = (values: string[]) => {
   console.log("list",values)
    if (!multiple) handleSingleSelectElement(values);
    else handleMultipleSelectElement(values);
  };
  const handleSingleSelectElement = (value: string[]) => {
    const selectedElement = getElementFromValue(value[0]);
    if (selectedElement) {
      setInputValue(selectedElement.value);
      setSelectedLabels([selectedElement.label]);
      setBeforeTextIcon(selectedElement.icon);
    }
  };
  const handleMultipleSelectElement = (values: string[]) => {
    const reSelectedTagsElements = props.options.filter((option) =>
      values.includes(option.value)
    );
    const selectedTagsLabel = reSelectedTagsElements.map(
      (option) => option.label
    );
    setSelectedLabels(selectedTagsLabel);
    setInputValue(values.join(','));
  };

  useEffect(() => {
    if (inputRef.current && inputValue !== undefined) {
      inputRef.current.value = inputValue;
      emitEvent();
    }
  }, [inputValue]);

  const getErrorMessage = props.errorMessage ?? '';
  const renderFooter = () => {
    if (withError)
      return <span className="text-red-600 text-xs">{getErrorMessage}</span>;
    return <></>;
  };
  const footerStyles = classNames({
    ['transition-all duration-300']: true,
    ['show']: props.errorMessage,
    ['notShow']: !props.errorMessage,
    ['h-3']: withError
  });
  const handleCloseTags = (tag: string) => {
    const remainedTagsLabel = selectedLabels.filter((label) => label !== tag);
    const remainedTagsElements = props.options.filter((element) =>
      remainedTagsLabel.includes(element.label)
    );
    const remainedTagsValues = remainedTagsElements.map(
      (option) => option.value
    );

    console.log('here', remainedTagsValues, remainedTagsLabel);
    setSelectedLabels(remainedTagsLabel);
    setInputValue(remainedTagsValues.join(','));
  };
  const MAX_DISPLAYED_TAGS = 3;

  const renderBeforeText = () => {
    if (multiple && selectedLabels.length !== 0) {
      const displayedLabels = selectedLabels.slice(0, MAX_DISPLAYED_TAGS);
      const hiddenCount = selectedLabels.length - displayedLabels.length;

      return {
        beforeText: (
          <div className="flex gap-1">
            {displayedLabels.map((label) => (
              <TagElement
                size={size}
                closable
                onClose={() => handleCloseTags(label)}
                key={label}
                label={label}
              />
            ))}
            {hiddenCount > 0 && (
              <span className="text-gray-500">+{hiddenCount}</span>
            )}
          </div>
        )
      };
    }
    if (showSelectedIcon) return { beforeText: BeforeTextIcon };
    return <></>;
  };

  useEffect(() => {
    if (props.selectedValues !== undefined && props.options) {
      if (props.selectedValues === '') {
        setSelectedLabels([]);
        setInputValue('');
      } else {
        const selectedOptions = props.options.filter((option) =>
          props.selectedValues?.split(',').includes(option.value)
        );
        setSelectedLabels(
          selectedOptions.map((selectedOption) => selectedOption.label)
        );
        setInputValue(props.selectedValues);
      }
    }
  }, [props.selectedValues, props.options]);

  return (
    <div ref={visibilityRef}>
      <input className="hidden" ref={inputRef} />
      <InputText
        size={size}
        ref={ref}
        onClick={open}
        label={props.label}
        errorMessage={props.errorMessage}
        placeholder={props.placeholder}
        icon={ExpandIcon}
        onlyRead
        value={inputValue}
        text={multiple && selectedLabels.length !== 0}
        removeRightRadius={props.removeRightRadius}
        removeLeftRadius={props.removeLeftRadius}
        disabled={props.disabled}
        {...renderBeforeText()}
      />
      <div className="relative">
        <div className={footerStyles}>{renderFooter()}</div>
        <List
          size={listSize}
          list={props.options}
          onChange={handleListChange}
          open={isOpen}
          selectedValues={getSelectedValues()}
          className="w-full z-50 top-1.5"
          setOpenStatus={setOpenStatus}
          preventCheck={props.preventCheck}
          closeAlways={props.alwaysClose}
          multiple={multiple}
          vertical={vertical}
        />
      </div>
    </div>
  );
};
export const Select = forwardRef(ForwardedSelect);
