import {createColumnHelper} from "@tanstack/react-table";
import {WindowOrganizationRequestsResponse} from "../../../../types";
import {getKarazFileUrl} from "../../../../../../shared/utils/helpers";
import {Menu} from "../../../../../../shared/components";
import {MaterialIcon} from "../../../../../../shared/components/icons";
import {Tag} from "antd";
import {REQUEST_MODULE_STATUS} from "../../../../../../shared/utils/constants";





export const getWindowRequestsColumns=()=> {
    const columnHelper = createColumnHelper<WindowOrganizationRequestsResponse>()
    const renderOrganizationImage=(gedId:string|undefined)=> {
        if (gedId) return <img className="rounded-full" src={getKarazFileUrl(gedId)} alt=""/>
        return <></>;
    }

    const approveJoinRequest=(id:string)=>{console.log(id)}
    const rejectJoinRequest=(id:string)=>{console.log(id)}


    const inProgressStatusMenuList=(id:string)=>[
        {label:"Approuver",value:"approve",icon:<MaterialIcon center label="check_circle" className="text-emerald-500 text-base"/>,onClick:()=>approveJoinRequest(id)},
        {label: "Refuser",value:"refuse",icon:<MaterialIcon center label="block" className="text-red-500 text-base"/>,onClick:()=>rejectJoinRequest(id)},
    ];
    const AcceptedStatusMenuList=[
        {label:"Retirer",value:"approve",icon:<MaterialIcon center label="delete" className="text-red-500 text-base"/>},
    ];
    const wrapStatusValue=(value:string)=>{
        switch (value){
            case REQUEST_MODULE_STATUS.DRAFT:return <Tag color="blue">En attente approbation</Tag>;
            case REQUEST_MODULE_STATUS.REJECTED:return <Tag color="red">rejetée</Tag>;
            case REQUEST_MODULE_STATUS.ACCEPTED:return <Tag color="green">Approuvé</Tag>;
            default : return value;
        }
    }
    const renderMenu=(value:string,id:string)=>{
       switch (value){
           case REQUEST_MODULE_STATUS.DRAFT:return <Menu list={inProgressStatusMenuList(id)} icon={menuIcon}/>;
           case REQUEST_MODULE_STATUS.ACCEPTED:return <Menu list={AcceptedStatusMenuList} icon={menuIcon}/> ;
       }
    }
    const menuIcon=<MaterialIcon label="more_vert" center className="text-md text-slate-600 text-xl"/>
    return ([
        columnHelper.accessor('organismName', {
            header:()=>("Guichet"),
            cell: info =>
                <div className="flex gap-2 items-center" >
                    <div className="flex justify-center  h-6 aspect-square rounded-full">{renderOrganizationImage(info.row.original.gedId)}</div>
                    <span className="font-medium text-sm">{info.getValue()}</span>
                </div>
        }),
        columnHelper.accessor('statusRequest', {
            header:()=>("Statut"),
            cell: info =>
                <div className="flex justify-between items-center el">
                    <span className="font-normal text-[13px] text-slate-700">{wrapStatusValue(info.getValue())}</span>
                    {renderMenu(info.getValue(),info.row.original.idRequest)}
               </div>
        }),
    ])
}