
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { message } from "antd";
import { useParams } from "react-router-dom";
import styles from '../eservice.param.module.css';
import { usegetEserviceDetails, useEServiceSettings } from "../../../../../api";
import { EServiceSettingsSchema } from "../../../../../holders/schemas";
import { EServiceDetails, EServiceSettings } from "../../../../../types";
import { DynamicListData } from "../../../../../../../shared/types";
import { NotificationType } from "../../../../../../../shared/utils/enums";
import { useHandleTranslation } from "../../../../../../../shared/hooks";
import { SimpleCard, NotificationCard, Button, MaterialIconSelect } from "../../../../../../../shared/components";
import { InputText, Select, TextArea, InputColor, InputTags } from "../../../../../../../shared/components/controlled-ui";
import { PubStates } from "../../../../../components/ui/pubstates/PubStates";

interface ApiData { industriesList: DynamicListData[] | undefined; }

interface ApiStatus { industriesList: boolean | undefined; }
interface EServiceSettingsProps {
  rootClassName?: string;
  isError?: boolean | undefined;
  backButton?: { label: string, onClick: () => void }
  isLoading: ApiStatus;
  isSuccess?: ApiStatus
  data: ApiData;
}
interface Catalog {
  titre: string;
  guichet: string;
  owner: string;
  publicationTime: string | null;
  iconMaterial: string;
  iconBgColor: string;
  catalogImage: string;
  description: string;
  industrie: string;
  tags: string[];
}

interface TagData {
  tags: string[];
}
interface ApiResponse { industrie: string; tags: TagData; catalog: Catalog }

export const EServiceInfoTab: FC<EServiceSettingsProps> = (props) => {
  const paramsID = useParams<{ eserviceID: string }>();
  const getEserviceDetails = usegetEserviceDetails();
  const updateEserviceSettings = useEServiceSettings();
  const { t: tO } = useHandleTranslation("window");
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [messageApi, contextHolder] = message.useMessage();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [catalogDetails, setCatalogDetails] = useState<Catalog>({ titre: '', guichet: '', owner: '', publicationTime: null, iconMaterial: '', iconBgColor: '', catalogImage: '', description: '', industrie: '', tags: [] });
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const { control, handleSubmit, formState: { errors }, reset } = useForm<EServiceSettings>({
    resolver: yupResolver(EServiceSettingsSchema), defaultValues: { color: "#0E7490" },
  });
  const errorMessageStyles = classNames({
    ["!my-4"]: true,
    ['notShow']: !isErrorDisplayed,
    ['show transition-all duration-500']: isErrorDisplayed
  });

  const renderindustriesOptions = () => {
    return props
      .data
      .industriesList
      ?.map((item: DynamicListData) => (
        {
          ...item,
          icon: <i className="material-flex-icon text-lg text-blue-600">{item.icon}</i>
        }))
  }
  const handleSubmitEserviceSettings = (data: EServiceSettings) => {
    updateEserviceSettings.mutate(data);
  }
  const onSubmit = (formData: EServiceSettings) => {
    handleSubmitEserviceSettings({
      ...formData, idEservice: paramsID.eserviceID
    });
    setIsEditMode(false);
  };
  const fetchEserviceDetails = (data: EServiceDetails) => {
    getEserviceDetails.mutate(data);
  }
  useEffect(() => {
    if (updateEserviceSettings.customHandler.errorMessage) {
      setErrorMessage(updateEserviceSettings.customHandler.errorMessage);
      setIsErrorDisplayed(true);
    } else {
      setIsErrorDisplayed(false);
    }
  }, [updateEserviceSettings.customHandler.errorMessage]);

  useEffect(() => {
    if (paramsID?.eserviceID) {
      fetchEserviceDetails({ idApplication: paramsID.eserviceID });
    }
  }, [paramsID?.eserviceID]);

  useEffect(() => {
    const apiResponse = getEserviceDetails?.customHandler?.data as ApiResponse[] | null;
    if (apiResponse && Array.isArray(apiResponse) && apiResponse.length > 0) {
      const catalogResponse = apiResponse[0]['catalog'];
      const tagResponse = apiResponse[0]['tags'];

      if (catalogResponse) {
        // Ensure tagResponse is a string array
        const tags: string[] = Array.isArray(tagResponse) ? tagResponse : [];

        setCatalogDetails({
          titre: catalogResponse.titre || '',
          guichet: catalogResponse.guichet || '',
          owner: catalogResponse.owner || '',
          publicationTime: catalogResponse.publicationTime || null,
          iconMaterial: catalogResponse.iconMaterial || '',
          iconBgColor: catalogResponse.iconBgColor || '',
          catalogImage: catalogResponse.catalogImage || '',
          description: catalogResponse.description || '',
          industrie: apiResponse[0]?.industrie || "",
          tags: tags,
        });
      }

    }
  }, [getEserviceDetails?.isSuccess]);

  useEffect(() => {
    console.log(catalogDetails, 'catalogDetails')
    reset({ name: catalogDetails?.titre === 'undefined' ? '' : catalogDetails?.titre, owner: catalogDetails?.owner === 'undefined' ? '' : catalogDetails.owner, description: catalogDetails?.description === 'undefined' ? '' : catalogDetails?.description, color: catalogDetails?.iconBgColor || "#0E7490", industrie: catalogDetails?.industrie || "", fileIcon: catalogDetails?.iconMaterial || "", tagEservice: catalogDetails?.tags });
  }, [catalogDetails, reset]);

  useEffect(() => {
    if (updateEserviceSettings.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({ content: "Informations modifiées avec succès.", duration: 2, style: { marginTop: "12vh", marginLeft: "auto", marginRight: "auto", zIndex: "1", borderRadius: "8px", border: "1px solid var(--mapping-slate-200)", width: "fit-content", boxShadow: "0px 2px 4px -2px #0000001A , 0px 4px 6px -1px #0000001A", } });
      fetchEserviceDetails({ idApplication: paramsID.eserviceID });
    }
  }, [updateEserviceSettings.customHandler.isSuccess]);

  useEffect(() => {
    if (errors.name) {
      setIsErrorDisplayed(false);
      messageApi.destroy();
      messageApi.error({ content: "Assurez-vous de remplir tous les champs obligatoires.", duration: 2, style: { marginTop: "25vh", marginLeft: "auto", marginRight: "auto", zIndex: "1", borderRadius: "8px", border: "1px solid var(--mapping-slate-200)", width: "fit-content", boxShadow: "0px 2px 4px -2px #0000001A , 0px 4px 6px -1px #0000001A" } });
    }
  }, [errors.name, messageApi]);

  const handlePubState = () => {

  }

  const handleEdit = () => setIsEditMode(true);

  const handleCancel = () => {
    setIsEditMode(false);
    reset(catalogDetails);
  };

  return (
    <SimpleCard spin={updateEserviceSettings.isPending || getEserviceDetails.isPending} className={styles.settingsContainer}>
      {isErrorDisplayed && <NotificationCard type={NotificationType.ERROR} className={errorMessageStyles}>{errorMessage} </NotificationCard>}
      {contextHolder}
      <div>
        <div className={styles.settingsForm}>
          <div className={styles.formHeader}>
            <InputText withError control={control} size="lg" label={tO("eService_name_label")} placeholder={tO("eService_name_placeholder")} name="name" error={errors.name?.message} errorMessage={errors.name?.message} disabled={!isEditMode} readOnly={!isEditMode} />
            <InputText value={tO("eService_owner_placeholder")} disabled readOnly={true} control={control} size="lg" label={tO("eService_owner_label")} placeholder={tO("eService_owner_placeholder")} name="owner" error={errors.owner?.message} errorMessage={errors.owner?.message} />
          </div>
          <div className={styles.formdescription}>
            <TextArea control={control} error={errors.description?.message} name="description" size="lg" label={tO("eService_description_label")} placeholder={tO("eService_description_placeholder")} disabled={!isEditMode} readOnly={!isEditMode} />
          </div>
        </div>
        <div className={styles.dividerLine}></div>
        <div className={styles.identityDetails}>
          <div className={styles.domainIconColor}>
            <div className={styles.dropdownDomain}>
              <Select showSelectedIcon size="lg" label={tO("eService_domain_label")} placeholder={tO("eService_domain_placeholder")} options={renderindustriesOptions() || []} alwaysClose name="industrie" selectedValues={control._defaultValues.industrie} control={control} error={errors.industrie?.message} disabled={!isEditMode} readOnly={!isEditMode} />
            </div>
            <div className={styles.dropdownIcon}>
              <MaterialIconSelect  size="lg" label={tO("eService_icon_label")} placeholder={tO("eService_icon_placeholder")} disabled={!isEditMode} readOnly={!isEditMode} />
            </div>
            <div className={styles.colorPicker}>
              <InputColor error={errors.color?.message} errorMessage={errors.color?.message} name="color" control={control} label={tO("eService_color_label")} size="lg" defaultValue={control._defaultValues.color || "#0E7490"} disabled={!isEditMode} readOnly={!isEditMode} />
            </div>
          </div>
          <div className={styles.tagInput}>
            <InputTags readOnly={!isEditMode} disabled={!isEditMode} label={tO("eService_tags_label")} placeholder={tO("eService_tags_placeholder")} control={control} name="tags" size="lg" errorMessage={errors.tagEservice?.message} />
          </div>
          <div className={styles.accountContainer}>
            <PubStates disabled={!isEditMode} handleClick={() => handlePubState()} status="Publié" value="green" type="color" description={tO("eService_pubStates_publicdescription")} actionText={tO("eService_pubStates_publicActionText")} />
            <PubStates disabled={!isEditMode} status="Privé" type="icon" value="visibility_off" description={tO("eService_pubStates_privatedescription")} actionText={tO("eService_pubStates_privateActionText")} />
          </div>
        </div>
        <div className={styles.actions}>
          {!isEditMode ? (
            <Button label={tO("Editer")} size="md" color="primary" styling={"solid"} onClick={handleEdit} />
          ) : (
            <>
              <Button label={tO("Annuler")} size="md" color="primary" styling={"outline"} onClick={handleCancel} />
              <Button label={tO("Enregistrer")} size="md" color="primary" styling={"solid"} onClick={handleSubmit(onSubmit)} />
            </>
          )}
        </div>
      </div>
    </SimpleCard>
  );
};