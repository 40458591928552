import { postFileCheck } from '../../../../../shared/utils/helpers/api.helper';
import { API_PATHS } from './apiPath';

export const checkForGraphs = async (file: File) => {
    const responseData = await postFileCheck(API_PATHS.CHECK_GRAPHS, file);
    return {
        found: responseData.found,
        message: responseData.message
    };
};

export const checkForMergedCells = async (file: File) => {
    const responseData = await postFileCheck(API_PATHS.CHECK_MERGED_CELLS, file);
    return {
        found: responseData.found,
        message: responseData.message
    };
};

export const checkForHeaders = async (file: File) => {
    const responseData = await postFileCheck(API_PATHS.CHECK_HEADERS, file);
    return responseData.has_header;
};

export const checkForDataConsistency = async (file: File) => {
    return postFileCheck(API_PATHS.CHECK_DATA_CONSISTENCY, file);
};
