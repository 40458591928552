import {FC, useEffect, useState} from "react";
import {useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {useCancelJoiningOrganization, useGetCheckWindowOrganizationStatus} from "../../../../../../api";
import {GuichetStatus} from "../../../../../../../../shared/utils/enums";
import {CheckBadgeIcon, ClockIcon} from "@heroicons/react/16/solid";
import classNames from "classnames";
import {Button, SimpleModal, TagElement} from "../../../../../../../../shared/components";
import {OrganizationIdentityTabsProps} from "../OrganizationIdentityTab";

export const OrganizationInProgressInterface:FC<OrganizationIdentityTabsProps>=(props)=>{
    const {className=""}=props
    const queryClient = useQueryClient()
    const {guichetId}=useParams();
    const [isModalOpen,setModalStatus]=useState<boolean>(false)
    const getCheckWindowOrganizationStatus=useGetCheckWindowOrganizationStatus({guichetId});
    const{messageApi}=props;
    const alertKey ="key-add"
    const cancelJoiningRequest=useCancelJoiningOrganization();
    const renderStateIcon=(status:string)=>{
        switch (status){
            case GuichetStatus.IN_PROGRESS: return <ClockIcon className="size-3 text-orange-600 "/>;
            case GuichetStatus.VERIFIED:  return <CheckBadgeIcon className="size-3 text-emerald-500"/>
        }
    }

    const renderOrganizationImage=(gedId:string|undefined)=> {
        if (gedId) return <img className="rounded-full" src={`/karazal/DownloadFile?gedId=${gedId}`} alt=""/>
        return <></>;
    }
    const rootSytles=classNames({
        [className]:true,
        ["flex flex-col gap-7"]:true
    })
    const closeModal=()=>{
        setModalStatus(false);
    }
    const openModal=()=>{
        setModalStatus(true);
    }
    const handleCancelRequestClick=()=>{
        const organisationId=getCheckWindowOrganizationStatus.customHandler.data?.organisationId;
        const joindemandeId=getCheckWindowOrganizationStatus.customHandler.data?.demandeJoindreId;
        if (organisationId && guichetId && joindemandeId)  cancelJoiningRequest.mutate({guichetId,organisationId,joindemandeId})
    }
    const handleValidate=()=>{
        closeModal();
        handleCancelRequestClick();
    }

    useEffect(() => {
        if (cancelJoiningRequest.isPending) {
            messageApi.loading({
                key: alertKey,
                content: "loading",
                duration: 0
            });
        }
    }, [cancelJoiningRequest.isPending]);

    useEffect(() => {
        if (cancelJoiningRequest.customHandler.isError) {
            messageApi.error({
                key: alertKey,
                content:cancelJoiningRequest.customHandler.errorMessage ,
                duration: 2.5
            });
        }
    }, [cancelJoiningRequest.customHandler.isError]);


    useEffect(() => {
        if (cancelJoiningRequest.customHandler.isSuccess) {
            messageApi.success({
                key: alertKey,
                content: cancelJoiningRequest.customHandler.successMessage,
                duration: 2.5
            });
            if (cancelJoiningRequest.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['organization-window-status'] })
        }
    }, [cancelJoiningRequest.customHandler.isSuccess]);
    return (
        <div className={rootSytles}>
            <SimpleModal type={"primary"} title="Annulation demande"  validateLabel="Valider" cancelLabel="Annuler" onValidate={handleValidate} afterClose={closeModal} onCancel={closeModal} isOpen={isModalOpen}>
                <span className="text-sm font-medium text-slate-800 dark:text-white">Êtes-vous sûr de vouloir annuler la demande ?</span>
            </SimpleModal>
            <span className="font-semibold text-sm text-blue-700">Demande d’association guichet à une organisation</span>
            <div className="flex gap-2">
                <div className="flex justify-center  h-10 aspect-square rounded-full">{renderOrganizationImage(getCheckWindowOrganizationStatus.data?.body?.gedId)}</div>
                <div className="flex w-full justify-between items-center">
                    <div className="flex items-center gap-1">
                        <span
                            className="font-medium text-sm text-slate-800 dark:text-white">{getCheckWindowOrganizationStatus.data?.body?.organisationName}</span>
                        {renderStateIcon("")}
                    </div>
                    <TagElement color="#C2410C" borderColor="#FB923C" label="En attente approbation" className="!bg-orange-50"/>
                </div>
            </div>
            <div className="flex justify-end w-full">
                <Button size="md" color="error" styling={"solid"} label="Annuler demande" onClick={openModal}/>
            </div>
        </div>
    )
}
