import { useMutation } from '@tanstack/react-query';
import {
  checkForGraphs,
  checkForMergedCells,
  checkForHeaders,
  checkForDataConsistency,
} from './api';

export const useCheckForGraphs = () => {
  return useMutation({
    mutationFn: async (file: File) => await checkForGraphs(file),
  });
};

export const useCheckForMergedCells = () => {
  return useMutation({
    mutationFn: async (file: File) => await checkForMergedCells(file),
  });
};

export const useCheckForHeaders = () => {
  return useMutation({
    mutationFn: async (file: File) => await checkForHeaders(file),
  });
};

export const useCheckForDataConsistency = () => {
  return useMutation({
    mutationFn: async (file: File) => await checkForDataConsistency(file),
  });
};
