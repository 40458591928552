import {FC, ReactNode, useEffect, useState} from "react";
import {Button} from "../button/button/Button";
import {MaterialIcon} from "../icons";
import {BaseModal} from "./base-modal/BaseModal";

interface SimpleModal{
    type:"primary" | "neutral" | "success" | "error" |"secondary";
    title?:string;
    isOpen:boolean;
    validateLabel:string;
    cancelLabel:string;
    onValidate?:()=>void;
    onCancel?:()=>void
    afterClose?:()=>void;
    disabled?:boolean;
    children:ReactNode;
}
export const SimpleModal:FC<SimpleModal>=(props)=>{
    const {isOpen=false,title=""}=props;
    const [hasOpen,setOpenStatus]=useState<boolean>(false)
    useEffect(() => {
        setOpenStatus(isOpen)
    }, [isOpen]);

    const handleCloseIcon=()=>{
        setOpenStatus(false);
        if (props.afterClose) props.afterClose();
    }
    return <BaseModal isOpen={hasOpen} afterClose={handleCloseIcon}  className="rounded-lg">
            <div className=" py-2 px-3 flex items-center justify-between border-b border-slate-200 dark:border-slate-700">
                <span className="text-slate-800 dark:text-white font-medium text-sm">{title}</span>
                <MaterialIcon label="close" className=" cursor-pointer text-base text-slate-500" onClick={handleCloseIcon}/>
            </div>

        <div className=" px-3 py-6 bg-white dark:bg-slate-800">
            {props.children}
        </div>
        <div className=" rounded-lg py-2 px-3 flex justify-between items-center bg-slate-50 dark:bg-slate-950 border-t border-slate-200 dark:border-slate-700 ">
            <Button color="neutral" styling="outline" label={props.cancelLabel} onClick={props.onCancel} size="sm" />
            <Button color={props.type} styling="solid" label={props.validateLabel} onClick={props.onValidate} size="sm" disabled = {props.disabled}/>
        </div>
    </BaseModal>
}