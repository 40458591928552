import styles from './list.module.css';
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useMemo,
  useState
} from 'react';
import classNames from 'classnames';
import { ListItemType, ListProps } from '../../types';
import { ListItem } from './list-item/ListItem';
import {ListVerticalItem} from "./list-vertical-item/ListVerticalItem";

const ListForwardedRef: ForwardRefRenderFunction<HTMLDivElement, ListProps> = (
  props,
  ref
) => {
  const [listElements, setListElements] = useState<ListItemType[]>();
  const {
    multiple = false,
    size = 'sm',
    className = '',
    preventCheck = false,
    action = false,
    closeAlways = false,
    vertical=false,
  } = props;
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const close = () => props.setOpenStatus(false);
  const single = !multiple;

  const persistValues = (values: string[]) => {
    setSelectedValues(values);
    if (props.onChange) props.onChange(values);
    if (action) {
      const element = props.list?.find(
        (element) => element.value === values[0]
      );
      if (element && element.onClick) element.onClick(values);
    }
  };
  const handleSelectElements = (value: string) => {
    if (!preventCheck && isItemSelected(value)) {
      if (multiple) HandleSelectExistingMultipleElements(value);
      if (closeAlways) close();
    } else {
      if (multiple) handleSelectNonExistingMultipleElements(value);
      if (single) {
        handleSelectNonExistingSingleElement(value);
        close();
      }
    }
  };
  const HandleSelectExistingMultipleElements = (value: string) => {
    setSelectedValues((prevSelectedValues) => {
      const reSelectedValues = prevSelectedValues.filter(
        (item) => item !== value
      );
      persistValues(reSelectedValues);
      return reSelectedValues;
    });
  };
  const handleSelectNonExistingMultipleElements = (value: string) => {
    if (selectedValues) persistValues([...selectedValues, value]);
    else persistValues([value]);
  };
  const handleSelectNonExistingSingleElement = (value: string) =>
    persistValues([value]);

  const isItemSelected = (value: string) =>
    selectedValues && selectedValues.includes(value);

  const handleSelectItemStatus = (value: string) => {
    if (preventCheck) return false;
    return isItemSelected(value);
  };

  const rootStyles = classNames({
    ['transition-all duration-200']: true,
    [className]: true,
    [styles.rootContainer]: true,
    [styles['portal-list-css-var']]: true,
    ['show']: props.open,
    ['notShow']: !props.open
  });
  const memoRootStyles = useMemo(() => rootStyles, [props.open]);

  const listContainerStyle = classNames({
    "grid grid-cols-4 gap-x-0 gap-y-6":vertical,
    ['max-h-40']: size === 'sm',
    ['max-h-44']: size === 'md'
  });
  const itemProps=(item:ListItemType,index:number)=>(
    {
    selected:handleSelectItemStatus(item.value),
    key:index,
    label:item.label,
    icon:item.icon,
    onClick:() => handleSelectElements(item.value)
  })
  const renderItem=(item:ListItemType,index:number)=>{
    if(vertical) return <ListVerticalItem size={size} {...itemProps(item,index)}/>
    return <ListItem size={size} {...itemProps(item,index)}/>
  }
  useEffect(() => {
    if (props.selectedValues && props.selectedValues.length !== 0)
      setSelectedValues(props.selectedValues);
    else setSelectedValues([]);
  }, [props.selectedValues]);

  useEffect(() => {
    if (props.list) setListElements(props.list);
  }, [props.list]);
  return (
    <div className={memoRootStyles} ref={ref}>
      <div className={listContainerStyle}>
        {listElements &&
          listElements.map((item, index) => (
          renderItem(item,index)
          ))}
      </div>
    </div>
  );
};

export const List = forwardRef(ListForwardedRef);
