import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AccueilPage } from '../pages/organisation-pages/AccueilPage';
import { OrganisationPage } from '../pages/organisation-pages/OrganisationPage';
import { DashboardPage } from '../pages/organisation-pages/DashboardPage';
import { EserviceListPage } from '../pages/organisation-pages/eservices-pages/EserviceListPage';
import { OffreOrgPage } from '../pages/organisation-pages/OffreOrgPage';
import { ParametresPage } from '../pages/organisation-pages/ParametresPage';
import { MonitoringPage } from '../pages/organisation-pages/MonitoringPage';
import { SecuritePage } from '../pages/organisation-pages/SecuritePage';
import { ContributeurPage } from '../pages/organisation-pages/ContributeurPage';
import { GroupesPage } from '../pages/organisation-pages/GroupesPage';
import { CommunautePage } from '../pages/organisation-pages/CommunautePage';
import { SuppressionPage } from '../pages/organisation-pages/SuppressionPage';
import { OffrePage } from '../pages/main-pages/OffrePage';
import { AcademyPage } from '../pages/main-pages/AcademyPage';
import { CataloguePage } from '../pages/main-pages/CataloguePage';
import { SupportPage } from '../pages/main-pages/SupportPage';
import { UtilisateursPage } from '../pages/organisation-pages/UtilisateursPage';
import { DeveloperZonePage } from '../pages/organisation-pages/DeveloperZonePage';
import { WindowIdentity, WindowPage} from '../pages';
import { EServiceParamsPage } from '../pages/organisation-pages/eservices-pages/eservices-params-page/EServiceParamsPage';
import { AdministrateusPage } from '../pages/organisation-pages/administrateurs-page/AdministrateusPage';
import { WindowMarketPlace } from '../pages/organisation-pages/window-marketplace/WindowMarketplace';
const OrganisationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route index element={<AccueilPage />} />
      <Route path="guichets">
        <Route
          index
          element={<WindowPage/>}
        />
        {/* <Route path="create-window" element={<CreateWindowPage />}/> */}
        <Route path=":guichetId">
          <Route path="" element={<DashboardPage />} />
          <Route path="guichet" element={<OrganisationPage />} />
          <Route path="eservices">
            <Route index element={<EserviceListPage />} />
            <Route path=":eserviceID">
              <Route index element={<EServiceParamsPage />} />
            </Route>
          </Route>
          <Route path="administrateurs" element={<AdministrateusPage />} />
          <Route path="identity" element={<WindowIdentity/>} />
          <Route index path="eservices" element={<EserviceListPage />} />
          <Route path="offre" element={<OffreOrgPage />} />
          <Route path="parameters" element={<ParametresPage />} />
          <Route path="monitoring" element={<MonitoringPage />} />
          <Route path="security" element={<SecuritePage />} />
          <Route path="contributors" element={<ContributeurPage />} />
          <Route path="users" element={<UtilisateursPage />} />
          <Route path="groups" element={<GroupesPage />} />
          <Route path="devzone" element={<DeveloperZonePage />} />
          <Route path="marketplace" element={<WindowMarketPlace />} />
          <Route path="communaute" element={<CommunautePage />} />
          <Route path="deleteorganisation" element={<SuppressionPage />} />
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="" replace />} />
      </Route>
      <Route path="offre" element={<OffrePage />} />
      <Route path="academy" element={<AcademyPage />} />
      <Route path="catalogue" element={<CataloguePage />} />
      <Route path="support" element={<SupportPage />} />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};

export default OrganisationRoutes;
