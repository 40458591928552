import { FC, useEffect, useRef, useCallback } from 'react';
import styles from './card-grid.module.css';
import {
  CARD_DROPDOWN_ADMIN,
  CARD_DROPDOWN_ALL,
  CARD_DROPDOWN_CITIZEN,
  CARD_DROPDOWN_CONTRIBUTOR_AD_CD,
  CARD_DROPDOWN_NO_ROLE,
  CARD_DROPDOWN_ADMINLIST
} from '../../../utils/constants/components.constants';
import { CardGuichet, Guichet } from '../cards/base-cards/CardGuichet';
import { CardEservice, Eservice } from '../cards/base-cards/CardEservice';
import { CardAdministrators, Administrator } from '../cards/base-cards/CardAdministrators';
import SkeletonGrid from './SkeletonGrid';

import { DynamicListData } from '../../../../../shared/types';
import { FavoriteEserviceRequest, FavoriteGuichetRequest } from '../../../types';
import { useHandleDomLocation } from '../../../hooks';

export interface CardGridProps {
  items: Guichet[] | Eservice[] | Administrator[];
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
  fetchMoreData: (page: number) => void;
  currentPage: number;
  totalPages: number;
  type: 'guichet' | 'eservice' | 'selectable' | 'administrateus';
  isFetching?: boolean | false;
  setFavoriteParams?: (params: FavoriteGuichetRequest) => void;
  setFavoriteEservice?: (params: FavoriteEserviceRequest) => void;

  dataDomaine?:DynamicListData[] | undefined;
  selectedItems?: Array<Eservice>;
  setSelectedItems?: (items: Array<Eservice>) => void;
}

export const CardGrid: FC<CardGridProps> = ({
  items,
  onSelectDropDown,
  fetchMoreData,
  currentPage,
  totalPages,
  type,
  isFetching,
  setFavoriteParams,dataDomaine,setFavoriteEservice,selectedItems,setSelectedItems
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { profilePathName ,isGuichetPage} = useHandleDomLocation()

  const getDropDownData = useCallback((role: string[] | string | undefined) => {
    if (profilePathName === 'utilisateur') return CARD_DROPDOWN_ALL ;
    if (!role && isGuichetPage) return CARD_DROPDOWN_ALL;
    else if(!role && !isGuichetPage){
      return CARD_DROPDOWN_NO_ROLE;
    }

    if (Array.isArray(role)) {
      if (role.includes('Contributeur') && role.includes('Citizen developer')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      if (role.includes('Contributeur') && role.includes('Administrateur')) {
        return CARD_DROPDOWN_CONTRIBUTOR_AD_CD;
      }
      
      if (role.includes('Administrateur')) {
        return CARD_DROPDOWN_ADMIN;
      }

      if (role.includes('Citizen developer')) {
        return CARD_DROPDOWN_CITIZEN;
      }
    }
  
    return CARD_DROPDOWN_ALL;
}, []);

const handleSelectItem = (item:Eservice, isSelected: boolean) => {
  if(selectedItems && setSelectedItems){
  const updatedSelection = isSelected
    ? [...selectedItems, item]
    : selectedItems.filter((selected) => selected.id !== item.id);

  setSelectedItems(updatedSelection);
  }
};

  // Fetch more items when needed
  const fetchMoreItems = useCallback(() => {
    if (currentPage >= totalPages) return;
    fetchMoreData(currentPage + 1);
  }, [totalPages, fetchMoreData, currentPage]);

  // Handle scroll event
  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollTop + clientHeight >= scrollHeight - 2) {
      fetchMoreItems();
    }
  }, [fetchMoreItems]);

  // Manage scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container && currentPage < totalPages) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container && currentPage < totalPages) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, currentPage, totalPages]);

  return (
    <>
      <div className={styles.cardsContainer} ref={containerRef}>
        {items.map((item, index) => {
          if ('role' in item && type === 'guichet') {
            return (
              <CardGuichet
                setFavoriteParams={setFavoriteParams}
                key={index}
                guichet={item as Guichet}
                dropDownData={getDropDownData(item.role)}
                cardAction={{
                  onSelectDropDown: (selection) => onSelectDropDown(selection)
                }}
                icon={
                  dataDomaine &&
                    item.industry &&
                    dataDomaine.filter(
                      (domaine) => domaine.label === item.industry
                    )[0]?.icon
                    ? dataDomaine.filter(
                      (domaine) => domaine.label === item.industry
                    )[0].icon
                    : undefined // Fallback if no icon is found
                }
              />
            );
          } else if(type === 'selectable' || type =='eservice'){
            return (
              <CardEservice
                key={index}
                eservice={item as Eservice}
                setFavoriteEservice={setFavoriteEservice}
                dropDownData={getDropDownData((item as Eservice).role)}
                cardAction={{
                  onSelectDropDown: (selection) => onSelectDropDown(selection)
                }}
              />
            );
          } else {
            return (
              <CardAdministrators
                key={index}
                administrator={item as Administrator}
                dropDownData={CARD_DROPDOWN_ADMINLIST}
                cardAction={{
                  onSelectDropDown: (selection: { id: string; selectedValue: string; }) => onSelectDropDown(selection)
                }}
                isSelected={selectedItems?.some((selected) => selected.id === item.id )|| false}
                onSelect={handleSelectItem}
                icon={
                  dataDomaine &&
                  item.industry &&
                  dataDomaine.filter(
                    (domaine) => domaine.label === item.industry
                  )[0]?.icon
                    ? dataDomaine.filter(
                        (domaine) => domaine.label === item.industry
                      )[0].icon
                    : undefined 
                }
              />
            );
          }
        })}
        {isFetching && <SkeletonGrid numberOfCards={6} />}
      </div>
    </>
  );
};
