import {FC} from "react";
import styles from './upload-cards.module.css'
import classNames from "classnames";
interface  DocumentCardProps{
    title: string;
    size:string;
    readOnly ?:boolean;
    onViewClick:()=>void;
    onRemoveClick:()=>void;
}
export const DocumentCard:FC<DocumentCardProps>=(props)=>{
 const {readOnly=false}=props;
    const rootStyles=classNames({
        [styles.container]:true,
        [styles["upload-card-css-var"]]:true
    })
    const renderDeleteAction=()=> {
        if (readOnly) return;
        return ( <i className="material-icon text-red-600 hover:text-red-800  !text-xl cursor-pointer"
           onClick={props.onRemoveClick}>delete</i>)
    }
    return (
        <div className={rootStyles}>
            <div className="flex gap-3 items-center">
                <i className={`material-icon !text-xl ${styles.iconColor}`}>description</i>
                <span className={styles.title}>{props.title}</span>
                <span className={styles.description}>{props.size}</span>
            </div>
            <div className="flex gap-2 items-center">
                <i className="material-icon text-blue-600 hover:text-blue-800 !text-xl cursor-pointer" onClick={props.onViewClick}>visibility</i>
                {renderDeleteAction()}
            </div>

        </div>
    )
}